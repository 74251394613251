import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  addTaskByPatientId,
  dischargePatientId,
  getClinicDashboardData,
  getClinicInpatients,
  getInpatientTasks,
  getProductsByClinic,
  getServices,
  updatePatientTaskStatusById,
} from "../../../redux/reducers/clinicSlice";
import { getClinicVets } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { taskCategory } from "../../../util/dropList";
import {
  createTimeSlots,
  getDateAndTimeList,
  getDateFormat,
  getTimeFormat,
  getTodayWithYMDFormat,
  isTimeBetween,
} from "../../../util/function";

const initialValues = {
  category: null,
  task: "",
  assignTo: "",
  startDate: new Date(),
  startTime: new Date(),
  repeatTask: false,
  endDate: new Date(),
  endTime: new Date(),
  noOfTimes: "",
  serviceId: null,
  productId: null,
};
const initialErrors = {
  category: false,
  task: false,
  assignTo: false,
  startDate: false,
  startTime: false,
  repeatTask: false,
  endDate: false,
  endTime: false,
  noOfTimes: false,
};
const initialHelpers = {
  category: "",
  task: "",
  assignTo: "",
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  repeatTask: "",
  noOfTimes: "",
};
const nameExpan = {
  category: "Category",
  task: "Task",
  assignTo: "Assign To",
  startDate: "Start Date",
  startTime: "Start Time",
  repeatTask: "Repeat Task",
  endDate: "End Date",
  endTime: "End Time",
  noOfTimes: "Number of Times",
};

const tableHeaders = ["petDetails", ""];

const Inpatient = ({ setSelectedTab, selectedVet }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});
  const [modOpen, setModOpen] = useState(false);
  const [taskValues, setTaskValues] = useState(initialValues);
  const [taskErrors, setTaskErrors] = useState(initialErrors);
  const [taskHelpers, setTaskHelpers] = useState(initialHelpers);
  const [tableList, setTableList] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);
  // const [tasks, setTasks] = useState([]);
  const [vets, setVets] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const inpatients = useSelector((state) => state?.clinic?.inpatients);
  // const inpatientTasks = useSelector((state) => state?.clinic?.inpatientTasks);
  const clinicVets = useSelector((state) => state.vet.vets);
  const [disCofrmModVsble, setDisConfrmModVsble] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  // const [page, setPage] = useState(1);
  // const itemsPerLoad = 3;

  // const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const products = useSelector((state) => state?.clinic?.products);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [preventiveList, setPreventiveList] = useState([]);
  const [medicationsWithType, setMedicationsWithType] = useState([]);
  const [supplemetsWithType, setSupplementsWithType] = useState([]);
  const services = useSelector((state) => state?.clinic?.services);
  const defaultUrl = "?type=all";

  useEffect(() => {
    getTimeList();
    dispatch(getInpatientTasks());
    dispatch(getClinicVets());
    dispatch(getProductsByClinic());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    let url = defaultUrl;
    if (selectedVet !== "All") url = `?type=doctor&doctorId=${selectedVet}`;
    dispatch(getClinicInpatients(url));
  }, [selectedVet]);

  useEffect(() => {
    getTableList();
  }, [inpatients]);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  // useEffect(() => {
  //   const reqMedList = products?.data
  //     ?.filter((prd) => prd?.category === "Medication")
  //     ?.map((pd) => ({
  //       ...pd,
  //       value: pd?.name,
  //     }));
  //   setMedicationsWithType(reqMedList);
  //   const reqPreventList = products?.data
  //     ?.filter(
  //       (prd) =>
  //         prd?.category === "Deworming" ||
  //         prd?.category === "Flea/tick treament" ||
  //         prd?.category === "Vaccines"
  //     )
  //     ?.map((pd) => ({
  //       ...pd,
  //       value: pd?.name,
  //     }));
  //   setPreventiveList(reqPreventList);
  // }, [products?.data]);

  // useEffect(() => {
  //   const diagList = services?.data
  //     ?.filter((ser) => ser?.category === "diagnostics")
  //     ?.map((dl) => ({
  //       ...dl,
  //       name: dl?.serviceName,
  //       value: dl?.serviceName,
  //     }));
  //   setDiagnosticList(diagList);
  // }, [services?.data]);

  // useEffect(() => {
  //   const reqList = inpatientTasks?.map((tsk) => ({
  //     name: tsk?.task,
  //     value: tsk?.task,
  //   }));
  //   setTasks(reqList);
  // }, [inpatientTasks]);

  const getTimeList = async () => {
    const reqTimes = await createTimeSlots("00:00", "23:59", 60);
    setTimeList(reqTimes);
  };

  const getTableList = () => {
    const reqList = inpatients?.data
      ?.filter((inpa) => !inpa?.discharge)
      ?.map((inp) => ({
        ...inp,
        petDetails: {
          petImage: inp?.petImage,
          petName: inp?.petName,
          gender: inp?.gender,
          breed: inp?.breed,
          kennelNumber: inp?.kennelNumber,
        },
        symptoms: inp?.remark,
        vet: {
          doctorName: inp?.doctorName,
          doctorSpeciality: inp?.doctorSpeciality,
        },
        toDoList: inp?.tasks,
      }));
    setTableList(reqList);
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event?.currentTarget ?? true }));
  };

  const handleModClose = () => {
    setModOpen(false);
    setSelectedDetails(null);
    setTaskValues(initialValues);
    setTaskErrors(initialErrors);
    setTaskHelpers(initialHelpers);
  };

  const handleChange = (name, value, addObj) => {
    const reqObj = { ...taskValues, [name]: value };
    if (name === "task" && addObj) {
      reqObj.productId = addObj?.productId;
      reqObj.serviceId = addObj?.serviceId;
    }
    setTaskValues(reqObj);

    setTaskErrors({
      ...taskErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
    setTaskHelpers({
      ...taskHelpers,
      [name]:
        value !== "" && value !== null
          ? ""
          : `${nameExpan?.[name]} is required`,
    });
  };

  const handleAddTask = (itm) => {
    setSelectedDetails(itm);
    setModOpen(true);
  };

  const handleDischarge = (itm) => {
    setSelectedPatientId(itm?.patientId);
    setDisConfrmModVsble(true);
  };

  const handleValidation = () => {
    let errs = {};
    let helps = {};
    Object.keys(taskValues).forEach((det) => {
      if (
        taskValues?.[det] === null ||
        taskValues?.[det] === "" ||
        taskValues?.[det]?.length === 0 ||
        !taskValues?.[det]
      ) {
        errs = {
          ...errs,
          [det]: true,
        };
        helps = {
          ...helps,
          [det]: `${nameExpan?.[det]} is required field`,
        };
      }
      delete errs.repeatTask;
      delete errs.serviceId;
      delete errs.productId;

      if (!taskValues?.repeatTask) {
        delete errs.noOfTimes;
        helps.noOfTimes = "";
      }
      setTaskErrors({ ...taskErrors, ...errs });
      setTaskHelpers({ ...taskHelpers, ...helps });
    });
    return Object.keys(errs).length > 0 ? true : false;
  };

  const handleSaveTask = async () => {
    const isNotValid = handleValidation();
    if (isNotValid) return;
    let tasks = [];
    if (taskValues?.repeatTask) {
      const reqDateAndTimes = getDateAndTimeList(
        taskValues?.startDate,
        taskValues?.endDate,
        taskValues?.startTime,
        taskValues?.endTime,
        taskValues?.noOfTimes
      );

      reqDateAndTimes?.filter((dt) => {
        const reqTask = {
          category: taskValues?.category,
          task: taskValues?.task,
          startDate: dt?.startDate,
          startTime: dt?.startTime,
          repeatTask: taskValues?.repeatTask,
          doctorId: taskValues?.assignTo,
          endDate: dt?.startDate,
          endTime: dt?.endTime,
          times: taskValues?.noOfTimes,
        };
        if (taskValues?.category !== "Others") {
          if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
          if (taskValues?.productId) reqTask.productId = taskValues?.productId;
        }
        tasks = [...tasks, reqTask];
      });
    } else {
      const reqTask = {
        category: taskValues?.category,
        task: taskValues?.task,
        startDate: getDateFormat(taskValues?.startDate),
        startTime: getTimeFormat(taskValues?.startTime),
        repeatTask: taskValues?.repeatTask,
        doctorId: taskValues?.assignTo,
      };
      if (taskValues?.category !== "Others") {
        if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
        if (taskValues?.productId) reqTask.productId = taskValues?.productId;
      }
      tasks = [reqTask];
    }
    if (tasks?.length === 0) return;
    dispatch(
      addTaskByPatientId({
        patientId: selectedDetails?.patientId,
        data: { tasks },
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(
          getClinicInpatients(defaultUrl)
          // defaultUrl
        );
        // setPage(1);
        handleModClose();
      }
    });
  };

  const updatePatientTaskById = (itm, status) => {
    dispatch(updatePatientTaskStatusById({ taskId: itm?.id, status })).then(
      (res) => {
        if (res?.payload) {
          dispatch(
            getClinicInpatients(defaultUrl)
            // `?page=${page}&itemSize=${itemsPerLoad}`
          );
        }
      }
    );
  };

  // const handleChangePage = (e, selectedPage) => {
  //   dispatch(
  //     getClinicInpatients(`?page=${selectedPage}&itemSize=${itemsPerLoad}`)
  //   );
  //   setPage(selectedPage);
  // };

  const handleCloseDisCnfrm = () => {
    setSelectedPatientId(null);
    setDisConfrmModVsble(false);
  };

  const handleConfirmDischarge = () => {
    if (selectedPatientId) {
      dispatch(dischargePatientId(selectedPatientId)).then((res) => {
        if (res?.payload) {
          handleCloseDisCnfrm();
          dispatch(getClinicDashboardData({ type: "Outpatient" }));
          setSelectedTab("Outpatient");
        }
      });
    }
  };

  const getTypeBasedList = (typ) => {
    if (typ === "Others") return [];
    if (typ === "Diagnostics") {
      return services?.data
        ?.filter((ser) => ser?.category === typ)
        ?.map((dl) => ({
          ...dl,
          name: dl?.serviceName,
          value: dl?.serviceName,
        }));
    }
    if (typ === "Medication") {
      return products?.data
        ?.filter(
          (prd) =>
            prd?.category === "Nutrition and Supplements" ||
            prd?.category === typ
        )
        ?.map((pd) => ({
          ...pd,
          value: pd?.name,
        }));
    }
    return products?.data
      ?.filter((prd) => prd?.category === typ)
      ?.map((pd) => ({
        ...pd,
        value: pd?.name,
      }));
  };

  return (
    <>
      <Grid container className="ph2">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mt3 back-white"
        >
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders?.map((hl, i) => (
                    <>
                      <TableCell
                        key={i}
                        style={
                          i === 0
                            ? {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "left",
                                position: "sticky",
                                left: 0,
                                zIndex: 105,
                                paddingLeft: 20,
                              }
                            : {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "center",
                              }
                        }
                        sortDirection={false}
                        className={`table-header-text capitalize `}
                      >
                        {hl === "petDetails" ? (
                          <div className="flex-center" style={{ width: 450 }}>
                            Pet Details
                          </div>
                        ) : (
                          <div className="flex-row-ali-cen mhMin16">
                            {timeList?.map((tm) => (
                              <div style={{ width: 150 }}>
                                <Typography className="header fs12">
                                  {tm}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList?.map((tl, i) => (
                  <TableRow tabIndex={-1}>
                    <TableCell
                      component="th"
                      className="tbl-brdr"
                      style={{
                        textAlign: "left",
                        position: "sticky",
                        background: AppColors.white,
                        left: 0,
                        zIndex: 100,
                      }}
                    >
                      <div style={{ width: 450 }}>
                        <div className="flex-row-ali-cen">
                          <div
                            className="w60Per cursor"
                            onClick={() => {
                              return navigate("/clinic-pet-details", {
                                state: {
                                  selectedTab: "admissionDetails",
                                  appointment: {
                                    appoinment: {
                                      appoimentId: tl?.appointmentId,
                                      petId: tl?.petId,
                                    },
                                  },
                                  inpatient: true,
                                  inpatientId: tl?.patientId,
                                  inpatDetails: tl,
                                },
                              });
                            }}
                          >
                            <div className="flex-row-ali-cen">
                              <div className="flex-start">
                                {tl?.petImage ? (
                                  <img
                                    src={tl?.petImage}
                                    alt=""
                                    className="pet-card-img"
                                  />
                                ) : (
                                  <div className="flex-center tbl-emp-img2">
                                    <Typography className="font-bold fs30 white-color capitalize">
                                      {tl?.petName?.[0]}
                                    </Typography>
                                  </div>
                                )}
                                <div className="ml10 flex-column">
                                  <div className="flex-row">
                                    <Custom3dotString
                                      str={tl?.petName}
                                      className="black6 txt-semi-bold fs16"
                                      length={12}
                                    />
                                    <Typography
                                      className={`capitalize header fs16 ml5 ${
                                        tl?.gender === "male"
                                          ? "card-blue-color"
                                          : "card-rose-color"
                                      }`}
                                    >
                                      ({tl?.gender})
                                    </Typography>
                                  </div>
                                  <Typography className="txt-regular black6 fs14">
                                    {tl?.breed}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex1-end pr10">
                                <div className="ver-bar h50" />
                              </div>
                            </div>
                          </div>
                          <div className="w40Per">
                            <Typography className="header red6 fs12">
                              Kennel Number: {tl?.kennelNumber}
                            </Typography>
                            <Typography className="black6 txt-semi-bold fs12 mt5">
                              Attended by Dr. {tl?.doctorName}
                            </Typography>
                            <div
                              style={{ position: "absolute", top: 2, right: 7 }}
                            >
                              <MoreVertIcon
                                className="card-3dot-color"
                                onClick={(event) => handleClick(event, i)}
                                size="small"
                                sx={{
                                  ml: 2,
                                  cursor: "pointer",
                                  transition: "color 0.3s ease",
                                  ":hover": {
                                    color: "#1976d2",
                                  },
                                }}
                                aria-controls={
                                  anchorEls[i] ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  anchorEls[i] ? "true" : undefined
                                }
                              />
                              <Menu
                                anchorEl={anchorEls[i]}
                                id="account-menu"
                                open={Boolean(anchorEls[i])}
                                onClose={() => handleClose(i)}
                                onClick={() => handleClose(i)}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&::before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleAddTask(tl)}
                                  className="txt-regular fs14"
                                >
                                  Add Task
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleDischarge(tl)}
                                  className="txt-regular fs14"
                                >
                                  Discharge
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div>
                        <div className="flex-row-ali-cen mt10 ipat-pet-det">
                          <div className="w90Per">
                            <Typography className="gray12 txt-semi-bold fs10">
                              {tl?.remark}
                            </Typography>
                          </div>

                          <div className="flex1-end">
                            {tl?.tasks
                              ?.filter(
                                (tsk) =>
                                  tsk?.startDate === getTodayWithYMDFormat()
                              )
                              ?.reduce((acc, obj) => {
                                return obj.status === false ? acc + 1 : acc;
                              }, 0) > 0 && (
                              <div className="flex-row-ali-cen">
                                <img
                                  src={require("../../../assets/images/png/dangerInfo.png")}
                                  alt=""
                                  className="img-wh20"
                                />
                                <div className="inpat-count">
                                  {tl?.tasks
                                    ?.filter(
                                      (tsk) =>
                                        tsk?.startDate ===
                                        getTodayWithYMDFormat()
                                    )
                                    ?.reduce((acc, obj) => {
                                      return obj.status === false
                                        ? acc + 1
                                        : acc;
                                    }, 0)}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <div className="flex-row-ali-cen tbl-brdr3">
                      {timeList?.map((tm, timInd) => {
                        const timeMatch = tl?.tasks?.filter(
                          (ap) =>
                            ap?.startDate === getTodayWithYMDFormat() &&
                            isTimeBetween(ap?.startTime, tm)
                        );
                        return (
                          <TableCell
                            component="th"
                            className={`flex-center ${
                              timInd === 0 ? "tbl-brdr4" : "tbl-brdr2 "
                            }`}
                            style={{ width: 150 }}
                          >
                            {timeMatch?.length > 0 ? (
                              <div className="flex-column">
                                {timeMatch?.map((tmt) => {
                                  const [hour, min] =
                                    tmt?.startTime?.split(":");
                                  const reqDate = new Date(
                                    new Date(tmt?.startDate).setHours(hour, min)
                                  );

                                  const isTimeExceed =
                                    new Date().getTime() > reqDate.getTime();

                                  return (
                                    <div className="flex-row-ali-cen">
                                      <CustomSwitch
                                        value={tmt?.status}
                                        onChange={(e) =>
                                          updatePatientTaskById(
                                            tmt,
                                            !tmt?.status
                                          )
                                        }
                                        grayRedGreen
                                        red={isTimeExceed}
                                        disabled={tmt?.status}
                                      />
                                      <Custom3dotString
                                        str={tmt?.task}
                                        className="txt-regular fs14 black6 ml5"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="flex-row-ali-cen"></div>
                            )}
                          </TableCell>
                        );
                      })}
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {tableList?.length === 0 && (
            <div className="flex-center h100">
              <Typography className="txt-regular">
                No records available
              </Typography>
            </div>
          )}
          {/* {Math.ceil(inpatients?.totalRecords / itemsPerLoad) > 1 ? (
            <div className="flex1-end mt10">
              <Pagination
                count={Math.ceil(inpatients?.totalRecords / itemsPerLoad)}
                variant="outlined"
                color="primary"
                page={page}
                onChange={handleChangePage}
              />
            </div>
          ) : null} */}
        </Grid>
      </Grid>

      <CustomModal
        open={modOpen}
        onClose={handleModClose}
        header="Add Task"
        rightModal
        modalWidth={30}
      >
        <div className="p20 scroll-80vh">
          <div className="inpat-border">
            <div className="flex-row-ali-cen">
              <div className="w75Per">
                <div className="flex-row-ali-cen">
                  {selectedDetails?.petImage ? (
                    <img
                      src={selectedDetails?.petImage}
                      alt=""
                      className="pet-card-img"
                    />
                  ) : (
                    <div className="flex-center tbl-emp-img2">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {selectedDetails?.petName?.[0]}
                      </Typography>
                    </div>
                  )}
                  <div className="ml10">
                    <div className="flex-column">
                      <div className="flex-row-ali-cen">
                        <Typography className="txt-semi-bold card-black fs14">
                          {selectedDetails?.petName}
                        </Typography>
                        <Typography
                          className={`capitalize header fs14 ml5 ${
                            selectedDetails?.gender === "male"
                              ? "card-blue-color"
                              : "card-rose-color"
                          }`}
                        >
                          ({selectedDetails?.gender})
                        </Typography>
                      </div>
                      <Typography className="card-black1 fs12 txt-regular">
                        {selectedDetails?.breed}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w2Per">
                <div className="ver-bar h50" />
              </div>
              <div className="flex1-end">
                <div className="flex1-end">
                  <div className="flex-column">
                    <div className="flex1-end">
                      <Typography className="txt-semi-bold fs12 black7">
                        {moment(selectedDetails?.dob).fromNow()}
                      </Typography>
                    </div>
                    <div className="flex1-end">
                      <div className="flex-row-ali-cen mt5">
                        <img
                          src={require("../../../assets/images/png/weight.png")}
                          alt=""
                          className="img-wh15"
                        />
                        <Typography className="txt-semi-bold fs12 card-black ml5">
                          {selectedDetails?.weight} kg
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="flex-row-ali-cen">
                <AccountCircleOutlinedIcon sx={{ width: 20, height: 20 }} />
                <Typography className="fs12 txt-semi-bold card-black ml5">
                  {selectedDetails?.userName}
                </Typography>
              </div>
              <div className="flex1-end">
                <Typography className="card-black1 fs12 txt-semi-bold">
                  Dr. {selectedDetails?.doctorName}
                </Typography>
              </div>
            </div>
          </div>
          <div className="inpat-blue-back mt20">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">Category</div>
                <Select
                  list={taskCategory}
                  value={taskValues?.category}
                  handleChange={(e) =>
                    handleChange("category", e?.target?.value)
                  }
                  select
                  error={taskErrors?.category}
                  helperText={taskHelpers?.category}
                  backgroundColor={AppColors.white}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">Task</div>
                {taskValues?.category === "Others" ? (
                  <CustomTextField
                    fullWidth
                    handleChange={(e) => {
                      handleChange("task", e?.target?.value);
                    }}
                    value={taskValues?.task}
                    helperText={taskHelpers?.task}
                    error={taskErrors?.task}
                  />
                ) : (
                  <Select
                    list={
                      getTypeBasedList(taskValues?.category)
                      // taskValues?.category === "Diagnostics"
                      //   ? diagnosticList
                      //   : taskValues?.category === "Preventive"
                      //   ? preventiveList
                      //   : taskValues?.category === "Medication"
                      //   ? [...medicationsWithType, ...supplemetsWithType]
                      //   : []
                    }
                    value={taskValues?.task}
                    error={taskErrors?.task}
                    helperText={taskHelpers?.task}
                    addNewSelect
                    handleChange={(e) => {
                      handleChange("task", e?.value ?? e, {
                        productId: e?.productId ?? null,
                        serviceId: e?.serviceId ?? null,
                      });
                    }}
                    backgroundColor={AppColors.white}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 mt10">Assign to</div>
              <Select
                list={vets}
                value={taskValues?.assignTo}
                handleChange={(e) => handleChange("assignTo", e?.target?.value)}
                select
                error={taskErrors?.assignTo}
                helperText={taskHelpers?.assignTo}
                backgroundColor={AppColors.white}
              />
            </Grid>

            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="txt-mont fs14">Start Date</div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) => {
                    handleChange("startDate", e?.target?.value);
                  }}
                  value={taskValues?.startDate}
                  helperText={taskHelpers?.startDate}
                  error={taskErrors?.startDate}
                  mobileDate
                  placeholder={nameExpan?.startDate}
                />
              </div>
              <div className="w50Per pl10">
                <div className="txt-mont fs14">Start Time</div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) =>
                    handleChange("startTime", e?.target?.value)
                  }
                  value={taskValues?.startTime}
                  helperText={taskHelpers?.startTime}
                  error={taskErrors?.startTime}
                  mobileTime
                  is12HourFomat={false}
                  placeholder={nameExpan?.startTime}
                  backgroundColor={AppColors.white}
                />
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="flex-row-ali-cen mt10">
                  <Typography className="txt-semi-bold fs12 mark-balck">
                    Repeat Task?
                  </Typography>
                  <div className="ml15 mt5">
                    <CustomSwitch
                      value={taskValues?.repeatTask}
                      onChange={(e) =>
                        handleChange("repeatTask", !taskValues?.repeatTask)
                      }
                      greenToGray
                    />
                  </div>
                </div>
              </div>
              {taskValues?.repeatTask && (
                <div className="w50Per pl10">
                  <div className="txt-mont fs14">Repeat Interval (in hrs)</div>
                  <CustomTextField
                    fullWidth
                    handleChange={(e) =>
                      handleChange("noOfTimes", e?.target?.value)
                    }
                    value={taskValues?.noOfTimes}
                    helperText={taskHelpers?.noOfTimes}
                    error={taskErrors?.noOfTimes}
                  />
                </div>
              )}
            </div>

            {taskValues?.repeatTask ? (
              <>
                <div className="flex-row-ali-cen mt10">
                  <div className="w50Per pr10">
                    <div className="txt-mont fs14">End Date</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) => {
                        handleChange("endDate", e?.target?.value);
                      }}
                      value={taskValues?.endDate}
                      helperText={taskHelpers?.endDate}
                      error={taskErrors?.endDate}
                      mobileDate
                      placeholder={nameExpan?.endDate}
                    />
                  </div>
                  <div className="w50Per pl10">
                    <div className="txt-mont fs14">End Time</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) =>
                        handleChange("endTime", e?.target?.value)
                      }
                      value={taskValues?.endTime}
                      helperText={taskHelpers?.endTime}
                      error={taskErrors?.endTime}
                      mobileTime
                      is12HourFomat={false}
                      placeholder={nameExpan?.endTime}
                      backgroundColor={AppColors.white}
                    />
                  </div>
                </div>
                <div className="flex-row-ali-cen mt10"></div>
              </>
            ) : null}
            <div className="flex-row-ali-cen mt20">
              <div className="flex1-end">
                <div>
                  <CustomButton smallBtn text="Save" onClick={handleSaveTask} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={disCofrmModVsble}
        onClose={handleCloseDisCnfrm}
        header="Discharge Pet"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to Discharge the patient?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton
                text="Yes"
                redBtn
                onClick={handleConfirmDischarge}
              />
            </div>
            <div className="ml10">
              <CustomButton text="No" onClick={handleCloseDisCnfrm} />
            </div>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Inpatient;
