import { initSlot } from "./object";

export const dayList = [
  { id: 1, name: "Monday", shortName: "M", value: "mon", isSelected: false },
  {
    id: 2,
    name: "Tuesday",
    shortName: "T",
    value: "tue",
    isSelected: false,
  },
  {
    id: 3,
    name: "Wednesday",
    shortName: "W",
    value: "wen",
    isSelected: false,
  },
  {
    id: 4,
    name: "Thursday",
    shortName: "T",
    value: "thu",
    isSelected: false,
  },
  { id: 5, name: "Friday", shortName: "F", value: "fri", isSelected: false },
  {
    id: 6,
    name: "Saturday",
    shortName: "S",
    value: "sat",
    isSelected: false,
  },
  { id: 7, name: "Sunday", shortName: "S", value: "sun", isSelected: false },
];

export const conditionList = [
  { id: 1, name: "Ears", value: "Ears" },
  { id: 2, name: "Eye", value: "Eyes" },
  { id: 3, name: "Mouth", value: "Mouth" },
  { id: 4, name: "Gastrointestinal", value: "Gastrointestinal" },
  { id: 5, name: "Limping or mobility", value: "Mobility" },
  { id: 6, name: "Respiratory", value: "Respiratory" },
  { id: 7, name: "Skin & coat", value: "Skin" },
  { id: 8, name: "Urinary", value: "renalUrinary" },
  { id: 9, name: "Emergency", value: "Emergency" },
  { id: 10, name: "General", value: "General" },
];

export const clinicFilterList = [
  { name: "Clinic Name", value: "name" },
  { name: "Location", value: "location" },
];

export const scheduleList = [
  { name: "Schedule", value: "Pending" },
  { name: "Mark as Complete", value: "Completed" },
];

export const statusList = [
  { name: "All", value: "All" },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Completed" },
  { name: "Overdue", value: "Overdue" },
];

export const LocationList = [
  { name: "Adyar", value: "Adyar" },
  { name: "Guindy", value: "Guindy" },
];

export const diagnosticList = [
  { name: "Complete Blood Count (CBC)", value: "Complete Blood Count (CBC)" },
  {
    name: "Erythrocyte Sedimentation Rate (ESR)",
    value: "Erythrocyte Sedimentation Rate (ESR)",
  },
  { name: "Serum Biochemical Profile", value: "Serum Biochemical Profile" },
  { name: "Blood Urea Nitrogen (BUN)", value: "Blood Urea Nitrogen (BUN)" },
  { name: "Creatinine", value: "Creatinine" },
  {
    name: "Liver Enzymes (ALT, AST, ALP)",
    value: "Liver Enzymes (ALT, AST, ALP)",
  },
  { name: "Electrolytes", value: "Electrolytes" },
  { name: "Routine Urinalysis", value: "Routine Urinalysis" },
  {
    name: "Specific Tests (Protein, Glucose, etc.)",
    value: "Specific Tests (Protein, Glucose, etc.)",
  },
  { name: "Fecal Floatation", value: "Fecal Floatation" },
  { name: "Fecal Smear", value: "Fecal Smear" },
  { name: "Fecal Occult Blood Test", value: "Fecal Occult Blood Test" },
  {
    name: "Thyroid Function Tests (T4, T3)",
    value: "Thyroid Function Tests (T4, T3)",
  },
  { name: "Cortisol Tests", value: "Cortisol Tests" },
  { name: "ELISA", value: "ELISA" },
  { name: "PCR", value: "PCR" },
  { name: "Western Blot", value: "Western Blot" },
  {
    name: "X-rays, Ultrasound, MRI, CT Scan",
    value: "X-rays, Ultrasound, MRI, CT Scan",
  },
  {
    name: "Skin Scraping, Fine Needle Aspirate",
    value: "Skin Scraping, Fine Needle Aspirate",
  },
  { name: "Biopsy", value: "Biopsy" },
  { name: "Culture and Sensitivity", value: "Culture and Sensitivity" },
  { name: "Electrocardiogram (ECG)", value: "Electrocardiogram (ECG)" },
  { name: "Echocardiogram", value: "Echocardiogram" },
  { name: "Intradermal Skin Testing", value: "Intradermal Skin Testing" },
  { name: "Serum Allergy Testing", value: "Serum Allergy Testing" },
];

export const profileSettingsDays = [
  {
    i: 0,
    name: "Sunday",
    value: "sun",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 1,
    name: "Monday",
    value: "mon",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 2,
    name: "Tuesday",
    value: "tue",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 3,
    name: "Wednesday",
    value: "wen",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 4,
    name: "Thursday",
    value: "thu",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 5,
    name: "Friday",
    value: "fri",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 6,
    name: "Saturday",
    value: "sat",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
];

export const ServiceList = [
  { id: 1, name: "Consultation (Virtual)", value: "Consultation (Virtual)" },
  { id: 2, name: "Consultation (Physical)", value: "Consultation (Physical)" },
  {
    id: 3,
    name: "Consultation (Home Visit)",
    value: "Consultation (Home Visit)",
  },
  { id: 4, name: "Wellness Exams", value: "Wellness Exams" },
  { id: 5, name: "Vaccinations", value: "Vaccinations" },
  { id: 6, name: "Emergency Care", value: "Emergency Care" },
  { id: 7, name: "Surgery", value: "Surgery" },
  { id: 8, name: "Dental Care", value: "Dental Care" },
  { id: 9, name: "Diagnostic Testing", value: "Diagnostic Testing" },
  { id: 10, name: "Behavioral Counseling", value: "Behavioral Counseling" },
  { id: 11, name: "Nutritional Counseling", value: "Nutritional Counseling" },
  { id: 12, name: "Microchipping", value: "Microchipping" },
  { id: 13, name: "Grooming", value: "Grooming" },
  { id: 14, name: "Senior Pet Care", value: "Senior Pet Care" },
  { id: 15, name: "Pet Boarding", value: "Pet Boarding" },
  {
    id: 16,
    name: "Euthanasia and End-of-Life Care",
    value: "Euthanasia and End-of-Life Care",
  },
];

export const roleList = [
  { id: 1, name: "Admin", value: "admin", isSelected: false },
  { id: 2, name: "Billing", value: "billing", isSelected: false },
  { id: 3, name: "Front Desk", value: "frontdesk", isSelected: false },
  { id: 4, name: "Management", value: "management", isSelected: false },
  { id: 5, name: "Pharmacy", value: "pharmacy", isSelected: false },
  { id: 6, name: "Veterinarian", value: "veterinarian", isSelected: false },
];
export const newRoleList = [
  {
    id: 1,
    name: "Junior Veterinarian",
    value: "Junior Veterinarian",
    isSelected: false,
  },
  {
    id: 2,
    name: "Senior Veterinarian",
    value: "Senior Veterinarian",
    isSelected: false,
  },
  { id: 3, name: "Specialist", value: "Specialist", isSelected: false },
];

export const InventoryList = [
  { id: 1, name: "Patient Sale", value: "Patient Sale", isSelected: false },
  { id: 2, name: "Adjustment Add", value: "Adjustment Add", isSelected: false },
  {
    id: 3,
    name: "Adjustment Minus",
    value: "Adjustment Minus",
    isSelected: false,
  },
  { id: 4, name: "Internal Use", value: "Internal Use", isSelected: false },
  { id: 5, name: "Stock Entry", value: "Stock Entry", isSelected: false },
];

export const NewRefundList = [
  { id: 1, name: "Yes", value: "Y" },
  { id: 2, name: "No", value: "N" },
];

export const PrepaidPostpaidList = [
  { id: 1, name: "Prepaid", value: "prepaid" },
  { id: 2, name: "Postpaid", value: "postpaid" },
];

export const PercentageZeroList = [{ id: 1, name: "0", value: "0" }];

export const Percentage50and100List = [
  { id: 1, name: "50", value: "50" },
  { id: 2, name: "100", value: "100" },
];

export const ConsultationList = [
  { id: 1, name: "Virtual", value: "Virtual", isSelected: false },
  { id: 2, name: "Physical", value: "Physical", isSelected: false },
  { id: 3, name: "HomeVisit", value: "HomeVisit", isSelected: false },
];

// export const productCategoryList = [
//   { name: "Medication", value: "Medication" },
//   { name: "Supplement", value: "Supplement" },
//   { name: "Vaccination", value: "Vaccines" },
//   { name: "Deworming", value: "Deworming" },
//   { name: "Flea/Tick Treatment", value: "Flea/tick treament" },
// ];

export const preventiveTypes = [
  { name: "Vaccination", value: "vaccination" },
  { name: "Deworming", value: "deworming" },
  { name: "Flea/Tick Treatment", value: "fleaTreatment" },
];

export const serviceCategoryList = [
  { name: "Diagnostics", value: "diagnostics" },
];

export const unitTypeList = [
  { id: 1, name: "Box", value: "Box", isSelected: false },
  { id: 2, name: "Bottle", value: "Bottle", isSelected: false },
  { id: 3, name: "Ointment", value: "Ointment", isSelected: false },
  { id: 4, name: "Pack", value: "Pack", isSelected: false },
  { id: 5, name: "Piece", value: "Piece", isSelected: false },
  { id: 6, name: "Strip", value: "Strip", isSelected: false },
  { id: 7, name: "Tablet", value: "Tablet", isSelected: false },
];

export const stockTransition = [
  { id: 1, name: "Stock Entry", value: "Stock Entry", isSelected: false },
  {
    id: 2,
    name: "Stock transcation",
    value: "Stock transcation",
    isSelected: false,
  },
];

export const issueList = [
  { id: 1, name: "Ears", value: "Ears" },
  { id: 2, name: "Eye", value: "Eyes" },
  { id: 3, name: "Mouth", value: "Mouth" },
  { id: 4, name: "Gastrointestinal", value: "Gastrointestinal" },
  { id: 5, name: "Limping", value: "Limping" },
  { id: 6, name: "Breathing", value: "Breathing" },
  { id: 7, name: "Skin", value: "Skin" },
  { id: 8, name: "Urine", value: "Urine" },
  { id: 9, name: "Emergency", value: "Emergency" },
  { id: 10, name: "General", value: "General" },
];

export const VaccineNames = [
  { id: 1, name: "Nobivac® Puppy DPV", value: "Nobivac® Puppy DPV" },
  { id: 2, name: "Vanguard Plus 5", value: "Vanguard Plus 5" },
  { id: 3, name: "Nobivac® Canine 1-Cv", value: "Nobivac® Canine 1-Cv" },
  { id: 4, name: "Duramune Max 5", value: "Duramune Max 5" },
  { id: 5, name: "Nobivac® Canine 1-Cv", value: "Nobivac® Canine 1-Cv" },
  { id: 6, name: "Vanguard Plus 5", value: "Vanguard Plus 5" },
  { id: 7, name: "Nobivac® Rabies", value: "Nobivac® Rabies" },
  { id: 8, name: "Rabvac 3", value: "Rabvac 3" },
  { id: 9, name: "Nobivac® Lepto4", value: "Nobivac® Lepto4" },
  { id: 10, name: "Vanguard L4", value: "Vanguard L4" },
  { id: 11, name: "Bronchi-Shield III", value: "Bronchi-Shield III" },
  { id: 12, name: "Nobivac® Intra-Trac3", value: "Nobivac® Intra-Trac3" },
  { id: 13, name: "Nobivac® PI", value: "Nobivac® PI" },
  { id: 14, name: "Nobivac® Lyme", value: "Nobivac® Lyme" },
  { id: 15, name: "Recombitek® Lyme", value: "Recombitek® Lyme" },
  { id: 16, name: "CaniLeish", value: "CaniLeish" },
  { id: 17, name: "Letifend", value: "Letifend" },
  { id: 18, name: "Babesia", value: "Babesia" },
  {
    id: 19,
    name: "Nobivac® Canine Flu H3N8",
    value: "Nobivac® Canine Flu H3N8",
  },
  { id: 20, name: "Canine Flu H3N2 Vaccine", value: "Canine Flu H3N2 Vaccine" },
  {
    id: 21,
    name: "NOBIVAC® CANINE FLU BIVALENT",
    value: "NOBIVAC® CANINE FLU BIVALENT",
  },
  { id: 22, name: "Vanguard® Civ H3n2", value: "Vanguard® Civ H3n2" },
  { id: 23, name: "Nobivac® Feline 1-HCPCh", value: "Nobivac® Feline 1-HCPCh" },
  { id: 24, name: "Fel-O-Vax PCT", value: "Fel-O-Vax PCT" },
  { id: 24, name: "Nobivac® Feline 1-HCPCh", value: "Nobivac® Feline 1-HCPCh" },
  { id: 25, name: "Fel-O-Vax PCT", value: "Fel-O-Vax PCT" },
  { id: 26, name: "Nobivac® FeLV", value: "Nobivac® FeLV" },
  { id: 27, name: "PUREVAX® FeLV", value: "PUREVAX® FeLV" },
  { id: 28, name: "Fel-O-Vax FIV", value: "Fel-O-Vax FIV" },
  { id: 29, name: "Nobivac® Feline 1-HCPCh", value: "Nobivac® Feline 1-HCPCh" },
  {
    id: 30,
    name: "Bordetella bronchiseptica",
    value: "Bordetella bronchiseptica",
  },

  { id: 31, name: "Clostridial vaccines", value: "Clostridial vaccines" },
  {
    id: 32,
    name: "Bovine Respiratory Syncytial Virus (BRSV) vaccine",
    value: "Bovine Respiratory Syncytial Virus (BRSV) vaccine",
  },
  {
    id: 33,
    name: "Infectious Bovine Rhinotracheitis (IBR) vaccine",
    value: "Infectious Bovine Rhinotracheitis (IBR) vaccine",
  },
  {
    id: 34,
    name: "Parainfluenza-3 (PI3) vaccine",
    value: "Parainfluenza-3 (PI3) vaccine",
  },
  {
    id: 35,
    name: "Bovine Viral Diarrhea (BVD) vaccine",
    value: "Bovine Viral Diarrhea (BVD) vaccine",
  },
  { id: 36, name: "Leptospirosis vaccine", value: "Leptospirosis vaccine" },
  {
    id: 37,
    name: "Foot and Mouth Disease (FMD) vaccine",
    value: "Foot and Mouth Disease (FMD) vaccine",
  },
  {
    id: 38,
    name: "Eastern/Western Equine Encephalomyelitis (EEE/WEE) vaccine",
    value: "Eastern/Western Equine Encephalomyelitis (EEE/WEE) vaccine",
  },
  { id: 39, name: "Tetanus Toxoid", value: "Tetanus Toxoid" },
  {
    id: 40,
    name: "West Nile Virus (WNV) vaccine",
    value: "West Nile Virus (WNV) vaccine",
  },
  { id: 41, name: "Rabies vaccine", value: "Rabies vaccine" },
  {
    id: 42,
    name: "Rhino pneumonitis vaccines (Equine Herpes Virus types 1 and 4)",
    value: "Rhino pneumonitis vaccines (Equine Herpes Virus types 1 and 4)",
  },
  {
    id: 43,
    name: "Clostridial vaccines (CD&T for Tetanus and Clostridium perfringens types C and D)",
    value:
      "Clostridial vaccines (CD&T for Tetanus and Clostridium perfringens types C and D)",
  },
  {
    id: 44,
    name: "Porcine Circovirus Type 2 (PCV2) vaccine",
    value: "Porcine Circovirus Type 2 (PCV2) vaccine",
  },
  {
    id: 45,
    name: "Porcine Circovirus Type 2 (PCV2) vaccine",
    value: "Porcine Circovirus Type 2 (PCV2) vaccine",
  },
  {
    id: 46,
    name: "Swine Influenza Virus (SIV) vaccine",
    value: "Swine Influenza Virus (SIV) vaccine",
  },
  { id: 47, name: "Erysipelas vaccine", value: "Erysipelas vaccine" },
  {
    id: 48,
    name: "Porcine Reproductive and Respiratory Syndrome (PRRS) vaccine",
    value: "Porcine Reproductive and Respiratory Syndrome (PRRS) vaccine",
  },
];

export const FleaBiteList = [
  { id: 1, name: "Capstar", value: "Capstar" },
  { id: 2, name: "Comfortis, Trifexis", value: "Comfortis, Trifexis" },
  { id: 2, name: "NexGard", value: "NexGard" },
  {
    id: 4,
    name: "Bravecto (Chewable and Topical)",
    value: "Bravecto (Chewable and Topical)",
  },
  { id: 5, name: "Credelio", value: "Credelio" },
  {
    id: 6,
    name: "Simparica, Simparica Trio",
    value: "Simparica, Simparica Trio",
  },
  {
    id: 7,
    name: "Frontline, Frontline Spray",
    value: "Frontline, Frontline Spray",
  },
  { id: 8, name: "Revolution, Stronghold", value: "Revolution, Stronghold" },
  { id: 9, name: "Vectra", value: "Vectra" },
  { id: 10, name: "Scalibor Protector Band", value: "Scalibor Protector Band" },
  { id: 11, name: "Seresto", value: "Seresto" },
  { id: 12, name: "Various brands", value: "Various brands" },
  {
    id: 13,
    name: "Advocate, Advantage Multi",
    value: "Advocate, Advantage Multi",
  },
  { id: 14, name: "Sentinel", value: "Sentinel" },
];

export const DewormingList = [
  {
    id: 1,
    name: "Nemex, Strongid T, Drontal",
    value: "Nemex, Strongid T, Drontal",
  },
  { id: 2, name: "Panacur, Safe-Guard", value: "Panacur, Safe-Guard" },
  {
    id: 3,
    name: "Droncit, Drontal, Profender",
    value: "Droncit, Drontal, Profender",
  },
  {
    id: 4,
    name: "Interceptor, Sentinel, Trifexis",
    value: "Interceptor, Sentinel, Trifexis",
  },
  {
    id: 5,
    name: "Quest, Advantage Multi, ProHeart",
    value: "Quest, Advantage Multi, ProHeart",
  },
  { id: 6, name: "Drontal, Panacur", value: "Drontal, Panacur" },
  { id: 7, name: "Drontal Plus", value: "Drontal Plus" },
  { id: 8, name: "Filaribits", value: "Filaribits" },
  { id: 9, name: "Valbazen", value: "Valbazen" },
  {
    id: 10,
    name: "Heartgard, Ivomec, Iverhart",
    value: "Heartgard, Ivomec, Iverhart",
  },
  { id: 11, name: "Tramisol, Levasole", value: "Tramisol, Levasole" },
  { id: 12, name: "Profender", value: "Profender" },
  { id: 13, name: "Dectomax", value: "Dectomax" },
  { id: 14, name: "Revolution, Stronghold", value: "Revolution, Stronghold" },
];

export const frequencyList = [
  { name: "1-1-1", value: "1-1-1" },
  { name: "1-0-1", value: "1-0-1" },
  { name: "1-1-0", value: "1-1-0" },
  { name: "1-0-0", value: "1-0-0" },
  { name: "0-1-0", value: "0-1-0" },
  { name: "0-0-1", value: "0-0-1" },
  { name: "0-1-1", value: "0-1-1" },
];

export const weekArray = [
  {
    day: "Sunday",
    value: "sunday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Monday",
    value: "monday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Tuesday",
    value: "tuesday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Wednesday",
    value: "wednesday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Thursday",
    value: "thursday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Friday",
    value: "friday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Saturday",
    value: "saturday",
    slots: [initSlot],
    isSelected: false,
  },
];
