import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainWithoutPadding from "../components/MainWithoutPadding";
import { sideNavVisibles } from "../redux/reducers/loaderSlice";
import { setAudioBlobData } from "../redux/reducers/recorderSlice";
import Header from "./header";

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const CustomLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sideNavVisible = useSelector((state) => state.loader.sideNavVisible);
  const accessToken = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");
  const recorder = useAudioRecorder();
  const [audioBlob, setAudioBlob] = useState(null);
  const { appointment, bill } = location?.state || {};

  const { recording, audSaved, visible } = useSelector(
    (state) => state?.recorder
  );

  // console.log("recording", recording, "saved", audSaved, "visible", visible);

  useEffect(() => {
    checkUserStatus();
  }, []);

  const checkUserStatus = () => {
    if (accessToken !== null) {
      if (role === "ROLE_ADMIN") {
        return navigate("/home");
      }
      if (role === "ROLE_DOCTOR") {
        return navigate("/vet-dashboard");
        // return navigate("/billinghome")
        // return navigate("/managementhome")
        // return navigate("/frontdeskhome")
        // return navigate("/pharmacyhome")
        // return navigate("/veterinaryhome")
      } else {
        return navigate("/dashboard");
      }
    } else {
      return navigate("/login");
    }
  };

  const handleSideNav = () => {
    dispatch(sideNavVisibles(false));
  };

  const addAudioElement = (blob) => {
    setAudioBlob(blob);
    dispatch(setAudioBlobData(blob));
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
    navigate(`/clinic-pet-details`, {
      state: {
        appointment,
        bill,
        selectedTab: "patientWorkbook",
      },
    });
  };

  return (
    <StyledRoot>
      <MainWithoutPadding>
        <Header name="VetInstant" />
        <Outlet />
        {visible && (
          <div
            className="flex-column p15"
            style={{
              // backgroundColor: "#F7F7F7",
              borderRadius: "10px",
              position: "absolute",
              right: 220,
              top: 40,
              width: "25%",
              alignItems: "center",
            }}
          >
            <Tooltip title="SOAP Note">
              <div>
                <AudioRecorder
                  recorder={recorder}
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  showVisualizer={true}
                  downloadFileExtension="mp3"
                  start
                />
              </div>
            </Tooltip>
          </div>
        )}
      </MainWithoutPadding>
    </StyledRoot>
  );
};

export default CustomLayout;
