import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import deleteImage from "../../../../assets/images/png/delete.png";
import editImage from "../../../../assets/images/png/edit.png";
import payImg from "../../../../assets/images/png/pay.png";
import print from "../../../../assets/images/png/print.png";
import saveImag from "../../../../assets/images/png/save.png";
import CustomButton from "../../../../components/CustomButton";
import Checkbox from "../../../../components/CustomCheckbox";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import Select from "../../../../components/Select/Select";
import {
  addBillingItemsByPaymentId,
  deletePaymentItemById,
  getBillDetailsByPaymentId,
  getProductsByClinic,
  getServices,
  updateBillingByPaymentId,
  updateBillingItemsByPaymentId,
  updatePayment,
} from "../../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../../util/AppColors";
import { PaymentTypeList } from "../../../../util/dropList";
import { getBorderColor } from "../../../../util/function";
import "./Billing.css";
import CustomCheckbox from "../../../../components/CustomCheckbox";

const tableHeaders = [
  "serviceitemname",
  "qty",
  "unitPrice",
  "tax",
  "adminCharge",
  "total",
  "pay",
];

const tableNameExpan = {
  serviceitemname: "Service/Item Name",
  qty: "Qty",
  unitPrice: "Price",
  tax: "Tax",
  adminCharge: "Admin Charge",
  total: "Total",
};

const initialValues = { quantity: 1, price: 0, unitType: "Unit" };
const initialPayValues = { amount: "", paymentMode: "cash" };
const initialDiscount = { type: "price", amt: "" };
const initialPayments = {
  subTotal: 0,
  tax: "-",
  discount: 0,
  amtPaid: 0,
  balDue: 0,
};

const Billing = ({ bill }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const contentToPrint = useRef(null);
  const billDetails = useSelector((state) => state?.clinic?.billDetails);
  const [selectedItem, setSelectedItem] = useState(null);
  const [discountValues, setDiscountValues] = useState(initialDiscount);
  const [searchVisible, setSearchVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [discountModVisible, setDiscountModVisible] = useState(false);
  const [delModVisible, setDelModVisible] = useState(false);
  const [productList, setProductList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState(initialPayments);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [itemValues, setItemValues] = useState(initialValues);
  const [prntPrevVsble, setPrntPrevVsble] = useState(false);
  const appDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const petDet = appDet?.pet;
  const apnt = appDet?.appointment;
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [payValues, setPayValues] = useState(initialPayValues);
  const [addDetails, setAddDetails] = useState([]);

  useEffect(() => {
    if (bill?.paymentId ?? petDet?.paymentId) {
      dispatch(getBillDetailsByPaymentId(bill?.paymentId ?? petDet?.paymentId));
    }
    getProductAndServiceListForDropdown();
  }, []);

  useEffect(() => {
    getPaymentDetails();
  }, [billDetails]);

  useEffect(() => {
    if (searchValue?.length > 0) {
      const reqList = [...(serviceList ?? []), ...(productList ?? [])]?.filter(
        (sp) => sp?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      );

      setFilteredList(reqList);
    }
  }, [searchValue]);

  const getPaymentDetails = () => {
    let subTotal = 0,
      tax = 0,
      discount = 0,
      balDue = 0;

    billDetails?.paymentDetails?.map((pd) => {
      tax = tax + Number(pd?.tax * pd?.quantity) ?? 0;
      subTotal = subTotal + pd?.total;
    });
    if (discountValues?.type && Number(discountValues?.amt) > 0) {
      if (discountValues?.type === "price") {
        discount = Number(discountValues?.amt);
      }
      if (discountValues?.type === "percentage") {
        discount = (subTotal / 100) * Number(discountValues?.amt);
      }
    }
    balDue = subTotal - (discount + billDetails?.amtPaid);
    setPaymentDetails({ ...paymentDetails, subTotal, tax, discount, balDue });
  };

  const getProductAndServiceListForDropdown = () => {
    let reqServices = [];
    let reqProducts = [];
    dispatch(getProductsByClinic()).then((res) => {
      if (res?.payload?.data?.length > 0) {
        reqProducts = res?.payload?.data?.map((pd) => {
          return { ...pd, value: pd?.productId, type: "Product" };
        });
      }
      setProductList(reqProducts);
    });
    dispatch(getServices()).then((res) => {
      if (res?.payload?.length > 0) {
        reqServices = res?.payload?.map((ser) => {
          return {
            ...ser,
            name: ser?.serviceName,
            value: ser?.serviceId,
            type: "Service",
          };
        });
      }
      setServiceList(reqServices);
    });
  };

  const handleDelete = (bill) => {
    setSelectedItem(bill);
    setDelModVisible(true);
  };

  const handleDelConfirm = async () => {
    const apiRes = await dispatch(
      deletePaymentItemById(selectedItem?.paymentDetailId)
    );
    if (apiRes?.payload) {
      handleUpdatePaymentValues();
      setSelectedItem(null);
      setDelModVisible(false);
    }
  };

  const handleEdit = (bill) => {
    setItemValues({
      ...itemValues,
      name: bill?.name,
      quantity: bill?.quantity,
      price: bill?.price,
      tax: bill?.tax,
      serviceId: bill?.serviceId ?? null,
      productId: bill?.productId ?? null,
      unitType: "Unit",
    });
    setSelectedItem(bill);
    setEditVisible(true);
  };

  const onDiscountModClose = () => {
    setDiscountModVisible(!discountModVisible);
  };

  const onChangeDiscount = (name, value) => {
    setDiscountValues({ ...discountValues, [name]: value });
  };

  const onChangeItemValue = (name, value) => {
    const reqObj = { ...itemValues, [name]: value };
    const reqPrduct = productList?.find(
      (prd) => prd?.productId === itemValues?.productId
    );
    if (
      itemValues?.productId &&
      name !== "price" &&
      (name === "unitType"
        ? value === "subUnit"
        : itemValues?.unitType === "subUnit")
    ) {
      reqObj.price = reqPrduct?.subunitSellingPrice;
    }
    if (
      itemValues?.productId &&
      name !== "price" &&
      (name === "unitType" ? value === "Unit" : itemValues?.unitType === "Unit")
    ) {
      reqObj.price = reqPrduct?.sellPrice;
    }
    setItemValues(reqObj);
  };

  const handleDelModClose = () => {
    setDelModVisible(false);
    setSelectedItem(null);
  };

  const handleAddDiscount = () => {
    getPaymentDetails();
    onDiscountModClose();
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    removeAfterPrint: true,
  });

  const handleUpdatePaymentValues = async () => {
    dispatch(
      getBillDetailsByPaymentId(
        location?.state?.bill?.paymentId ?? bill?.paymentId ?? petDet?.paymentId
      )
    ).then(async (res) => {
      const pay = res?.payload;

      let subTotal = 0,
        tax = 0,
        discount = 0;

      pay?.paymentDetails?.map((pd) => {
        tax = tax + Number(pd?.tax * pd?.quantity) ?? 0;
        subTotal = subTotal + pd?.total;
      });
      if (discountValues?.type && Number(discountValues?.amt) > 0) {
        if (discountValues?.type === "price") {
          discount = Number(discountValues?.amt);
        }
        if (discountValues?.type === "percentage") {
          discount = (subTotal / 100) * Number(discountValues?.amt);
        }
      }
      const reqData = {
        paymentId:
          location?.state?.bill?.paymentId ??
          pay?.paymentId ??
          petDet?.paymentId,
        data: {
          totalAmt: Number(subTotal ?? 0),
          amtPaid: Number(pay?.amtPaid ?? 0),
          discount: Number(discount ?? 0),
          tax: Number(tax ?? 0),
        },
      };
      const apiRes = await dispatch(updateBillingByPaymentId(reqData));
      if (apiRes?.payload) {
        dispatch(
          getBillDetailsByPaymentId(
            location?.state?.bill?.paymentId ??
              bill?.paymentId ??
              petDet?.paymentId
          )
        );
      }
    });
  };

  const handleAddItem = async (item) => {
    const reqObj = {
      name: item?.name ?? searchValue,
      price: item?.servicePrice ?? item?.sellPrice ?? 0,
      quantity: 1,
      tax: item?.tax ?? "0",
      total:
        Number(item?.servicePrice ?? item?.sellPrice ?? 0) +
        Number(item?.tax ?? 0),
      type: item ? (item?.serviceId ? "service" : "product") : "service",
    };
    const reqData = {
      paymentId:
        location?.state?.bill?.paymentId ??
        bill?.paymentId ??
        petDet?.paymentId,
      data: reqObj,
    };
    const apiRes = await dispatch(addBillingItemsByPaymentId(reqData));

    if (apiRes?.payload) {
      handleUpdatePaymentValues();
      setSearchValue("");
      setSearchVisible(false);
    }
  };

  const handleUpdateItem = async (item) => {
    let prdDet = null;
    let price = Number(itemValues?.price);
    if (itemValues?.productId) {
      const reqDeta = addDetails?.find(
        (add) => add?.productId === itemValues?.productId
      );
      if (reqDeta?.adminCharge) {
        prdDet = productList?.find(
          (prd) => prd?.productId === itemValues?.productId
        );
        price = price + Number(prdDet?.administeringCharges ?? 0);
      }
    }

    const reqObj = {
      name: itemValues?.name,
      price: itemValues?.price,
      quantity: itemValues?.quantity,
      tax: item?.tax ?? "0",
      total:
        Number(price * itemValues?.quantity) +
        Number(itemValues?.quantity * Number(item?.tax ?? 0)),
      type: item?.type,
      paymentDetaisId: selectedItem?.paymentDetailId ?? item?.paymentDetailId,
    };
    const reqData = {
      paymentId:
        location?.state?.bill?.paymentId ??
        bill?.paymentId ??
        petDet?.paymentId,
      data: reqObj,
    };
    const apiRes = await dispatch(updateBillingItemsByPaymentId(reqData));
    if (apiRes?.payload) {
      handleUpdatePaymentValues();
      setSelectedItem(null);
      setEditVisible(false);
    }
  };

  const handleClosePrintModal = () => {
    setPrntPrevVsble(false);
  };

  const finalPayment = () => {
    const data = {
      paymentId: billDetails?.paymentId,
      paymentMode: payValues?.paymentMode,
      paymentAmount: payValues?.amount,
    };

    dispatch(updatePayment(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        if (bill?.paymentId ?? petDet?.paymentId) {
          dispatch(
            getBillDetailsByPaymentId(bill?.paymentId ?? petDet?.paymentId)
          );
        }
        setPayModalVisible(false);
        setPayValues(initialPayValues);
      }
    });
  };

  const payModClose = () => {
    setPayValues(initialValues);
    setPayModalVisible(!payModalVisible);
  };

  const handleChangeValues = (name, value) => {
    setPayValues({ ...payValues, [name]: value });
  };

  const handleChangeAddDetailsValue = (name, value, id) => {
    const reqAddDetails = addDetails?.find((det) => det?.productId === id);
    let reqDetails = [];
    if (reqAddDetails) {
      reqDetails = addDetails?.map((add) => ({ ...add, [name]: value }));
    } else {
      reqDetails = [...reqDetails, { [name]: value, productId: id }];
    }
    setAddDetails(reqDetails);
  };

  return (
    <div className="back-white w100Per">
      <Container maxWidth="xl">
        <Box className="pv20">
          <TableContainer sx={{ maxHeight: 250 }}>
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders?.map((hl, i) => (
                    <TableCell
                      key={i}
                      style={{
                        backgroundColor: AppColors.tableGrey,
                        color: AppColors.white,
                        textAlign: "left",
                      }}
                      sortDirection={false}
                      className={`table-header-text ${
                        hl === "name" ? "w30Per" : "w15Per"
                      }`}
                    >
                      {tableNameExpan?.[hl]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {billDetails?.paymentDetails
                  ?.filter((pd) => pd?.type === "consultation")
                  ?.map((con) => {
                    const reqObj = {
                      serviceitemname: `Consultation(${billDetails?.appointmentType})`,
                      qty:
                        selectedItem?.paymentDetailId ===
                          con?.paymentDetailId && editVisible ? (
                          <CustomTextField
                            value={itemValues?.quantity}
                            handleChange={(e) =>
                              onChangeItemValue("quantity", e?.target?.value)
                            }
                            fullWidth
                            type="number"
                          />
                        ) : (
                          con?.quantity
                        ),
                      unitPrice:
                        selectedItem?.paymentDetailId ===
                          con?.paymentDetailId && editVisible ? (
                          <CustomTextField
                            value={itemValues?.price}
                            handleChange={(e) =>
                              onChangeItemValue("price", e?.target?.value)
                            }
                            fullWidth
                            type="number"
                          />
                        ) : (
                          `Rs ${con?.price}`
                        ),
                      tax: Number(con?.tax) > 0 ? con?.tax : "-",
                      total: `Rs ${con?.total}`,
                    };
                    if (apnt?.appoinmentStatus !== "completed") {
                      reqObj.pay =
                        selectedItem?.paymentDetailId ===
                          con?.paymentDetailId && editVisible ? (
                          <div
                            className="cursor"
                            onClick={() => handleUpdateItem(con)}
                          >
                            <img src={saveImag} alt="" className="imghw30" />
                          </div>
                        ) : (
                          <div className="flex-row">
                            <div
                              className="cursor"
                              onClick={() => handleDelete(con)}
                            >
                              <img
                                src={deleteImage}
                                alt=""
                                className="imghw30"
                              />
                            </div>
                            <div
                              className="cursor ml15"
                              onClick={() => handleEdit(con)}
                            >
                              <img src={editImage} alt="" className="imghw30" />
                            </div>
                          </div>
                        );
                    }
                    return reqObj;
                  })
                  ?.map((bil, ind) => (
                    <TableRow tabIndex={-1} key={ind}>
                      {tableHeaders?.map((th, index) => (
                        <TableCell
                          key={index + th + "tr"}
                          component="th"
                          className="table-text-black capitalize"
                        >
                          <div className="upload-row">
                            <div>{bil[th]}</div>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {billDetails?.paymentDetails
                  ?.filter((pd) => pd?.type !== "consultation")
                  ?.map((bil) => {
                    const reqProduct = productList?.find(
                      (prd) => prd?.productId === bil?.productId
                    );
                    const reqDetl = addDetails?.find(
                      (add) => add?.productId === bil?.productId
                    );

                    const reqObj = {
                      serviceitemname:
                        selectedItem?.paymentDetailId ===
                          bil?.paymentDetailId && editVisible ? (
                          <CustomTextField
                            value={itemValues?.name}
                            handleChange={(e) =>
                              onChangeItemValue("name", e?.target?.value)
                            }
                            fullWidth
                          />
                        ) : (
                          <>
                            {/* <div> */}
                              {bil?.name}
                              {/* </div> */}
                            {/* <div className="mt10 gray-color">
                              Admin Charges Rs{" "}
                              {reqProduct?.administeringCharges ?? 0}
                            </div> */}
                          </>
                        ),
                      qty:
                        selectedItem?.paymentDetailId ===
                          bil?.paymentDetailId && editVisible ? (
                          <div className="flex-row-ali-cen">
                            <div
                              style={{
                                width: bil?.productId ? "35%" : "100%",
                              }}
                            >
                              <CustomTextField
                                value={itemValues?.quantity}
                                handleChange={(e) =>
                                  onChangeItemValue(
                                    "quantity",
                                    e?.target?.value
                                  )
                                }
                                fullWidth
                                type="number"
                              />
                            </div>
                            {bil?.productId && (
                              <div style={{ width: "60%", paddingLeft: "5%" }}>
                                <Select
                                  list={[
                                    {
                                      name: reqProduct?.measurementUnit,
                                      value: "Unit",
                                    },
                                    {
                                      name: reqProduct?.subMeasurementUnit,
                                      value: "subUnit",
                                    },
                                  ]}
                                  value={itemValues?.unitType}
                                  handleChange={(e) => {
                                    onChangeItemValue(
                                      "unitType",
                                      e?.target?.value
                                    );
                                    handleChangeAddDetailsValue(
                                      "unitType",
                                      e?.target?.value,
                                      reqProduct?.productId
                                    );
                                  }}
                                  name="unitType"
                                  select
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          bil?.quantity
                        ),
                      unitPrice:
                        selectedItem?.paymentDetailId ===
                          bil?.paymentDetailId && editVisible ? (
                          <CustomTextField
                            value={itemValues?.price}
                            handleChange={(e) =>
                              onChangeItemValue("price", e?.target?.value)
                            }
                            fullWidth
                            type="number"
                          />
                        ) : (
                          `Rs ${bil?.price}`
                        ),
                      adminCharge:
                        selectedItem?.paymentDetailId ===
                          bil?.paymentDetailId && editVisible ? (
                          <CustomCheckbox
                            checked={reqDetl?.adminCharge}
                            onChange={() =>
                              handleChangeAddDetailsValue(
                                "adminCharge",
                                !reqDetl?.adminCharge ?? true,
                                reqProduct?.productId
                              )
                            }
                          />
                        ) : (
                          `Rs ${reqProduct?.administeringCharges ?? "0"}`
                        ),
                      tax: bil?.tax > 0 ? `Rs ${bil?.tax}` : "-",
                      total: `Rs ${bil?.reqTotal}`,
                    };

                    let reqTotal =
                      selectedItem?.paymentDetailId === bil?.paymentDetailId &&
                      editVisible
                        ? Number(itemValues?.quantity) *
                            Number(itemValues?.price) +
                          Number(itemValues?.quantity * bil?.tax ?? 0)
                        : bil?.total;

                    if (reqDetl?.adminCharge) {
                      const price =
                        Number(itemValues?.price) +
                        Number(reqProduct?.administeringCharges ?? 0);
                      reqTotal =
                        Number(price * itemValues?.quantity) +
                        Number(
                          itemValues?.quantity * Number(itemValues?.tax ?? 0)
                        );
                    }

                    if (apnt?.appoinmentStatus !== "completed") {
                      reqObj.pay =
                        selectedItem?.paymentDetailId ===
                          bil?.paymentDetailId && editVisible ? (
                          <div
                            className="cursor"
                            onClick={() => handleUpdateItem(bil)}
                          >
                            <img src={saveImag} alt="" className="imghw30" />
                          </div>
                        ) : (
                          <div className="flex-row">
                            <div
                              className="cursor"
                              onClick={() => handleDelete(bil)}
                            >
                              <img
                                src={deleteImage}
                                alt=""
                                className="imghw30"
                              />
                            </div>
                            <div
                              className="cursor ml15"
                              onClick={() => handleEdit(bil)}
                            >
                              <img src={editImage} alt="" className="imghw30" />
                            </div>
                          </div>
                        );
                    }

                    reqObj.reqTotal = reqTotal;
                    reqObj.productId = itemValues?.productId;

                    return reqObj;
                  })
                  ?.map((bil, ind) => {
                    return (
                      <TableRow tabIndex={-1} key={ind}>
                        {tableHeaders?.map((th, index) => (
                          <TableCell
                            key={index + th + "tr"}
                            component="th"
                            className="table-text-black capitalize"
                          >
                            <div className="upload-row">
                              <div>
                                {th === "total"
                                  ? `Rs ${bil?.reqTotal}`
                                  : bil?.[th]}
                              </div>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      {searchVisible ? (
        <div className="mv20 mh20">
          <div className="pr60 pl10">
            <CustomTextField
              placeholder="Search"
              fullWidth
              handleChange={(e) => setSearchValue(e?.target?.value)}
              value={searchValue}
            />
          </div>
          {searchValue?.length > 0 ? (
            <div className="br8brblack pv10 ph10 mt10">
              <div className="flex-row mv5">
                <div className="w25Per">
                  <Typography className="txt-regular fs14 blue-color">
                    Name
                  </Typography>
                </div>
                <div className="w25Per">
                  <Typography className="txt-regular fs14 blue-color">
                    Type
                  </Typography>
                </div>
                <div className="w25Per">
                  <Typography className="txt-regular fs14 blue-color">
                    Category
                  </Typography>
                </div>
                <div className="w25Per">
                  <Typography className="txt-regular fs14 blue-color">
                    Price
                  </Typography>
                </div>
              </div>
              {filteredList?.length > 0 ? (
                <div className="scroll-100px">
                  {filteredList?.map((fil, i) => {
                    return (
                      <div
                        className="bill-itm cursor"
                        key={i + "fil"}
                        onClick={() => handleAddItem(fil)}
                      >
                        <div className="w25Per">
                          <Typography className="txt-regular fs14">
                            {fil?.name}
                          </Typography>
                        </div>
                        <div className="w25Per">
                          <Typography className="txt-regular fs14">
                            {fil?.type}
                          </Typography>
                        </div>
                        <div className="w25Per">
                          <Typography className="txt-regular fs14">
                            {fil?.category}
                          </Typography>
                        </div>
                        <div className="w25Per">
                          <Typography className="txt-regular fs14">
                            {fil?.servicePrice ?? fil?.sellPrice
                              ? `Rs ${fil?.servicePrice ?? fil?.sellPrice}`
                              : "-"}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex-center mt20">
                  <Typography className="txt-regular">
                    No records available
                  </Typography>
                </div>
              )}

              {filteredList?.length > 0 ? null : (
                <div className="flex-row flex-center">
                  <div className="flex-start">
                    <Typography className="fs14">{searchValue}</Typography>
                  </div>
                  <div className="flex1-end mt20">
                    <div className="w15Per">
                      <CustomButton
                        text="Add New"
                        smallBtn
                        onClick={() => handleAddItem()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}

      {apnt?.appoinmentStatus !== "completed" ? (
        <div className="flex-row ph20">
          <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
            <CustomButton
              text="Add"
              smallBtn
              onClick={() => setSearchVisible(true)}
            />
          </Grid>
          <Grid item xs={5} sm={5} md={3} lg={3} xl={3} className="ml15">
            <CustomButton
              text="Apply Discount"
              smallBtn
              onClick={() => setDiscountModVisible(true)}
            />
          </Grid>
        </div>
      ) : null}

      <div className="billingcontainer mt20">
        <div className="billingBox">
          <div className="flex-row-between-align-center w60Per">
            <div className="txtstyle">Discount</div>
            <div className="txtstyle">
              {paymentDetails?.discount > 0
                ? `Rs ${paymentDetails?.discount}`
                : "-"}
            </div>
          </div>
          <div className="flex-row-between-align-center w60Per">
            <div className="txtstyle">Subtotal</div>
            <div className="txtstyle">
              Rs {paymentDetails?.subTotal - paymentDetails?.tax ?? 0}
            </div>
          </div>
          <div className="flex-row-between-align-center w60Per">
            <div className="txtstyle">Tax</div>
            <div className="txtstyle">
              {paymentDetails?.tax > 0 ? `Rs ${paymentDetails?.tax}` : "-"}
            </div>
          </div>

          <div className="flex-row-between-align-center w60Per mt15 amounttxtcontainer">
            <div
              style={{
                fontSize: "14px",
                color: "#5D9911",
              }}
            >
              Amount Paid
            </div>
            <div
              style={{
                fontSize: "14px",
                color: "#5D9911",
              }}
            >
              {billDetails?.amtPaid > 0 ? `Rs ${billDetails?.amtPaid}` : "-"}
            </div>
          </div>
          <div className="flex-row mt15">
            <div className="flex-row-between-align-center w60Per balancecontainer">
              <div className="balancetext">Balance due</div>
              <div className="balancetext">Rs {paymentDetails?.balDue}</div>
            </div>
            <div className="flex-center ml20">
              <div
                className="flex-center cursor"
                onClick={() => {
                  setPrntPrevVsble(true);
                }}
              >
                <img src={print} alt="" className="imghw30" />
              </div>
              <div
                className="flex-center cursor ml20"
                onClick={() => {
                  setPayValues({
                    ...payValues,
                    amount: billDetails?.balanceDue ?? 0,
                  });
                  setPayModalVisible(true);
                }}
              >
                <img src={payImg} alt="" className="img-h40" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        open={prntPrevVsble}
        onClose={handleClosePrintModal}
        header=""
        modal
        modalWidth={70}
      >
        <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <div ref={contentToPrint} className="bg">
            <div className="flex-row-ali-cen mv20">
              <div className="w30Per">
                {profile?.image ? (
                  <img src={profile?.image} className="h75ml30img" alt="" />
                ) : (
                  <div className="flex-center h50img ml40">
                    <Typography className="font-bold fs30 white-color capitalize">
                      {profile?.name?.[0]}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="w40Per flex-center">
                <div className="font-bold fs18 blue-color">INVOICE</div>
              </div>
              <div className="w30Per flex1-end">
                <div className="header-right-text2">
                  Business address <br />
                  City, State, IN - 000 000 <br />
                  TAX ID 00XXXXX1234X0XX
                </div>
              </div>
            </div>

            <div className="line"></div>
            <div className="flex1-end mt10 ph30">
              <div className="flex-column">
                <div className="flex-row-ali-cen">
                  <Typography className="font-bold fs14 gray4">
                    Payment Status
                  </Typography>
                  <Typography
                    className={`ml10 fs16 font-bold capitalize ${
                      billDetails?.status === "paid"
                        ? "green2"
                        : billDetails?.status === "unpaid"
                        ? "red2"
                        : "orange-color"
                    }`}
                  >
                    {billDetails?.status}
                  </Typography>
                </div>
                <div className="flex-row-ali-cen">
                  <Typography className="font-bold fs14 gray4">
                    Total
                  </Typography>
                  <Typography className="black fs16 font-bold ml10">
                    Rs {paymentDetails?.subTotal}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="lower-container">
              <div className="box-container">
                <div className="row-container">
                  <div>
                    <div className="colum-header">Pet Name</div>
                    <div className="colum-data">{petDet?.petName}</div>
                  </div>
                  <div>
                    <div className="colum-header">Parent Name</div>
                    <div className="colum-data capitalize">
                      {petDet?.userName}
                    </div>
                  </div>
                  <div>
                    <div className="colum-header">Invoice Number</div>
                    <div className="colum-data">#AB2324-01</div>
                  </div>
                  <div>
                    <div className="colum-header">Invoice Date</div>
                    <div className="colum-data">
                      {moment().format("DD MMM YYYY")}
                    </div>
                  </div>
                </div>

                <div className="thin-line"></div>

                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>SERVICE / ITEM NAME</th>
                      <th>QTY</th>
                      <th>PRICE</th>
                      <th>DISCOUNT (Rs)</th>
                      <th>TAX</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billDetails?.paymentDetails
                      ?.filter((pd) => pd?.type === "consultation")
                      ?.map((con) => (
                        <tr>
                          <td>
                            <b>
                              Consultation ({con?.name})
                              {billDetails?.doctorName
                                ? ` - Dr. ${billDetails?.doctorName}`
                                : ""}
                            </b>
                          </td>
                          <td>1</td>
                          <td>Rs {con?.price}</td>
                          <td>-</td>
                          <td>{con?.tax > 0 ? con?.tax : "-"}</td>
                          <td>Rs {con?.total}</td>
                        </tr>
                      ))}

                    {billDetails?.paymentDetails
                      ?.filter((pd) => pd?.type !== "consultation")
                      ?.map((itm, i) => (
                        <tr key={i}>
                          <td>{itm?.name}</td>
                          <td>{itm?.quantity}</td>
                          <td>Rs {itm?.price}</td>
                          <td>-</td>
                          <td>{itm?.tax > 0 ? itm?.tax : "-"}</td>
                          <td>Rs {itm?.total}</td>
                        </tr>
                      ))}

                    <tr>
                      <td colSpan="6">
                        <hr className="thin-line2" />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3"></td>
                      <td>
                        <div className="txtstyle">Discount</div>
                      </td>
                      <td colSpan="1"></td>
                      <td>
                        <div className="txtstyle">
                          {paymentDetails?.discount > 0
                            ? `Rs ${paymentDetails?.discount}`
                            : "-"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td>
                        <div className="txtstyle">Subtotal</div>
                      </td>
                      <td colSpan="1"></td>
                      <td>
                        <div className="txtstyle">
                          Rs{" "}
                          {paymentDetails?.subTotal - paymentDetails?.tax ?? 0}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td>
                        <div className="txtstyle">Tax</div>
                      </td>
                      <td colSpan="1"></td>
                      <td>
                        <div className="txtstyle">
                          {paymentDetails?.tax > 0
                            ? `Rs ${paymentDetails?.tax}`
                            : "-"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <div className="amounttxtcontainer ph5">
                        <td>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#5D9911",
                            }}
                          >
                            Amount Paid
                          </div>
                        </td>
                      </div>
                      <td colSpan="1" className="amounttxtcontainer ph5"></td>
                      <div className="amounttxtcontainer ph5">
                        <td>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#5D9911",
                            }}
                          >
                            {billDetails?.amtPaid > 0
                              ? `Rs ${billDetails?.amtPaid}`
                              : "-"}
                          </div>
                        </td>
                      </div>
                    </tr>
                    <div className="mt10" />
                    <tr>
                      <td colSpan="3"></td>
                      <div className="balancecontainer ph5">
                        <td>
                          <div className="balancetext">Balance due</div>
                        </td>
                      </div>

                      <td colSpan="1" className="balancecontainer ph5"></td>

                      <div className="balancecontainer ph5">
                        <td>
                          <div className="balancetext">
                            Rs {paymentDetails?.balDue}
                          </div>
                        </td>
                      </div>
                    </tr>
                    <div className="mb20" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="back-white"
          >
            <div className="flex-end">
              <div className="mr20 mv20">
                <CustomButton
                  text="Download"
                  onClick={() =>
                    handlePrint(null, () => contentToPrint.current)
                  }
                  smallBtn
                />
              </div>
            </div>
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={discountModVisible}
        onClose={onDiscountModClose}
        header="Apply Discount"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={0.5} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 selectypetext ">
              Discount Type
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Checkbox
              radio
              onChange={(e) => onChangeDiscount("type", e?.target?.value)}
              radios={[
                { label: "Price", value: "price" },
                { label: "Percentage", value: "percentage" },
              ]}
              defaultValue="price"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextField
              label="Discount Price"
              name="amt"
              fullWidth
              handleChange={(e) => onChangeDiscount("amt", e?.target?.value)}
              value={discountValues?.amt}
              labelTop
            />
          </Grid>

          <div className="flex1-end mt20">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CustomButton text="Add" onClick={handleAddDiscount} />
            </Grid>
          </div>
        </Grid>
      </CustomModal>

      <CustomModal
        open={delModVisible}
        onClose={handleDelModClose}
        header="Delete"
        modal
        modalWidth={30}
      >
        <Typography className="flex-center txt-regular fs14">
          Are you sure want to delete the list?
        </Typography>

        <div className="flex-center mb10 mt15">
          <div>
            <CustomButton text="Cancel" onClick={handleDelModClose} />
          </div>
          <div className="ml10">
            <CustomButton redBtn text="Confirm" onClick={handleDelConfirm} />
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={payModalVisible}
        onClose={payModClose}
        header="Pay"
        rightModal
        modalWidth={30}
      >
        <Card
          sx={{
            borderRadius: 1,
            padding: 2,
            borderTopColor: `${getBorderColor(billDetails?.status)}`,
          }}
          className="CustomCard-back-bill-payment"
        >
          <div className="maint">
            <div className="flex-row topt">
              <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                <div className="flex-center">
                  {petDet?.image ? (
                    <CardMedia
                      image={petDet?.image}
                      className="CustomCard-img3-bill"
                    />
                  ) : (
                    <div className="flex-center h50img">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {petDet?.petName?.[0]}
                      </Typography>
                    </div>
                    // <div className="CustomCard-empty-img" />
                  )}
                </div>
              </Grid>
              <div className="flex-row">
                <div className="flex-start">
                  <div className="flex-column">
                    <div className="flex-row">
                      <Typography
                        variant="body1"
                        className="mb10 font-bold fs14 capitalize"
                      >
                        {petDet?.petName}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`ml5 capitalize font-medium fs14 ${
                          petDet?.gender === "male"
                            ? "card-blue-color"
                            : "card-rose-color"
                        }`}
                      >
                        {`(${petDet?.gender})`}
                      </Typography>
                    </div>
                    <Typography
                      variant="body2"
                      className="mb10 txt-regular card-gray-color fs12"
                    >
                      {petDet?.breed}
                    </Typography>
                  </div>
                </div>
              </div>
              <div />
            </div>
            <div>
              <div className="flex-row parentcontainer">
                <div className="flex-row  iconcontainer">
                  <AccountCircleOutlinedIcon sx={{ width: 25, height: 25 }} />
                  <Typography
                    variant="body1"
                    className="  font-bold fs14 capitalize flex-center h35"
                  >
                    {petDet?.userName}
                  </Typography>
                </div>
                <div className="meeting-doctor capitalize">
                  Meeting Dr. {apnt?.doctorName}
                </div>
              </div>
              <div className="trdatenew">{apnt?.appoinmentDate}</div>
              <div
                className={
                  billDetails?.balanceDue === 0 ||
                  billDetails?.balanceDue === "Nil"
                    ? "balancedueblue"
                    : "balanceduered"
                }
              >
                Balance due: {billDetails?.balanceDue}
              </div>
              <div className="datecontainer"></div>
            </div>
          </div>
          <div
            className={`conttype ${
              apnt?.appointmentType === "Physical"
                ? "card-con-blue-back"
                : "virtual-bg-color"
            }`}
          >
            {apnt?.appointmentType}
          </div>
        </Card>
        <Grid container spacing={2} className="ph20">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CustomTextField
              label="Amount"
              name="amount"
              fullWidth
              handleChange={(e) =>
                handleChangeValues("amount", e?.target?.value)
              }
              value={payValues?.amount}
              labelTop
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Select
              list={PaymentTypeList}
              value={payValues?.paymentMode}
              handleChange={(e) =>
                handleChangeValues("paymentMode", e?.target?.value)
              }
              name="paymentMode"
              label="Payment Mode"
              select
              labelTop
            />
          </Grid>

          <div className="flex1-end mt20">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CustomButton text="Pay" onClick={finalPayment} />
            </Grid>
          </div>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default Billing;
