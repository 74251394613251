import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Table from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  createNewProduct,
  deleteProducts,
  editClinicProduct,
  getProductsByClinic,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { AppColors } from "../../../util/AppColors";
import {
  natureList,
  productCategoryList,
  productMeasurementUnit,
  productSubMeasurementUnit,
  yesNoValueList,
} from "../../../util/dropList";

const tableHeaders = [
  "productName",
  "category",
  "subCategory",
  "sellingPrice",
  "tax",
  "editButton",
];

const requiredFieldList = [
  "category",
  "subCategory",
  "name",
  "tradeName",
  "measurementUnit",
  "subMeasurementUnit",
  // "manufacturingDate",
  // "expiryDate",
  "location",
  "sellPrice",
  "subunitSellingPrice",
  // "administeringCharges",
  "taxApplicability",
  "tax",
];

const initialValues = {
  category: "",
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: "",
  code: "",
  brand: "",
  measurementUnit: "",
  subMeasurementUnit: "",
  formula: "",
  batchNumber: "",
  manufacturingDate: new Date(),
  expiryDate: new Date(),
  location: "",

  sellPrice: "",
  subunitSellingPrice: "",
  administeringCharges: "",
  taxApplicability: "",
  tax: "",

  dosageRecommendations: "",
  composition: "",
  sideEffects: "",
  instructions: "",
  description: "",
};

const initialError = {
  category: false,
  subCategory: false,
  name: false,
  tradeName: false,
  ingredient: false,
  nature: false,
  code: false,
  brand: false,
  measurementUnit: false,
  subMeasurementUnit: false,
  formula: false,
  batchNumber: false,
  manufacturingDate: false,
  expiryDate: false,
  location: false,

  sellPrice: false,
  subunitSellingPrice: false,
  administeringCharges: false,
  taxApplicability: false,
  tax: false,

  dosageRecommendations: false,
  composition: false,
  sideEffects: false,
  instructions: false,
  description: false,
};

const initialHelp = {
  category: "",
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: "",
  code: "",
  brand: "",
  measurementUnit: "",
  subMeasurementUnit: "",
  formula: "",
  batchNumber: "",
  manufacturingDate: "",
  expiryDate: "",
  location: "",

  sellPrice: "",
  subunitSellingPrice: "",
  administeringCharges: "",
  taxApplicability: "",
  tax: "",

  dosageRecommendations: "",
  composition: "",
  sideEffects: "",
  instructions: "",
  description: "",
};

const ProductComponent = () => {
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state?.clinic?.products);
  const [modVisible, setModVisible] = useState(false);
  const [productValues, setProductValues] = useState(initialValues);
  const [productErrors, setProductErrors] = useState(initialError);
  const [productHelps, setProductHelps] = useState(initialHelp);
  const [delModVisible, setDelModVisible] = useState(false);
  const [tableProducts, setTableProducts] = useState([]);
  const [productId, setProductId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}&search=${searchValue}`;

  useEffect(() => {
    dispatch(getProductsByClinic(defaultUrl));
  }, [searchValue]);

  useEffect(() => {
    if (productsData?.data?.length > 0) getSupplierTable(productsData?.data);
  }, [productsData?.data]);

  const getSupplierTable = (product) => {
    dispatch(showLoader());
    const requiredSupplier = product?.map((products, i) => {
      return {
        productCode: products?.code,
        productName: products?.name,
        unitType: products?.unitType,
        sellingPrice: products?.sellPrice,
        recorderLevel: products?.reoder,
        category: products?.category,
        subCategory: products?.subCategory,
        description: products?.description,
        tax: products?.tax,

        deleteButton: (
          <div
            onClick={() => handleDeleteModal(products?.productId)}
            className="red-color txt-semi-bold cursor "
          >
            <img
              src={require("../../../assets/images/png/del.png")}
              alt=""
              className="h30"
            />
          </div>
        ),
        editButton: (
          <div
            onClick={() => handleEditModal(products?.productId)}
            className="blue-color txt-semi-bold cursor "
          >
            <img
              src={require("../../../assets/images/png/edit.png")}
              alt=""
              className="h30"
            />
          </div>
        ),
      };
    });
    dispatch(hideLoader());
    setTableProducts(requiredSupplier);
  };

  const handleModalClose = () => {
    setProductId(null);
    setProductValues(initialValues);
    setProductErrors(initialError);
    setProductHelps(initialHelp);
    setModVisible(false);
  };

  const handleSubmit = async () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      if (productValues?.[fld]?.length === 0 || !productValues?.[fld]) {
        errObj = {
          ...errObj,
          [fld]:
            fld === "tax"
              ? productValues?.taxApplicability === "No"
                ? false
                : true
              : true,
        };
        hlpObj = {
          ...hlpObj,
          [fld]:
            fld === "tax"
              ? productValues?.taxApplicability === "No"
                ? ""
                : "This is reuired field"
              : "This is reuired field",
        };
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setProductErrors({ ...productErrors, ...errObj });
    setProductHelps({ ...productHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;

    const productData = {
      category: productValues?.category,
      subCategory: productValues?.subCategory,
      name: productValues?.name,
      tradeName: productValues?.tradeName,
      ingredient: productValues?.ingredient,
      nature: productValues?.nature,
      code: productValues?.code,
      brand: productValues?.brand,
      measurementUnit: productValues?.measurementUnit,
      subMeasurementUnit: productValues?.subMeasurementUnit,
      formula: productValues?.formula,
      batchNumber: productValues?.batchNumber,
      manufacturingDate: productValues?.manufacturingDate,
      expiryDate: productValues?.expiryDate,
      location: productValues?.location,
      sellPrice: parseFloat(productValues?.sellPrice),
      subunitSellingPrice: parseFloat(productValues?.subunitSellingPrice),
      administeringCharges: productValues?.administeringCharges,
      taxApplicability: productValues?.taxApplicability,
      tax: parseFloat(productValues?.tax),
      dosageRecommendations: productValues?.dosageRecommendations,
      composition: productValues?.composition,
      sideEffects: productValues?.sideEffects,
      instructions: productValues?.instructions,
      description: productValues?.description,
    };

    try {
      let apiRes = null;
      let reqUrl = defaultUrl;
      if (productId) {
        apiRes = await dispatch(
          editClinicProduct({ id: productId, data: productData })
        );
        reqUrl = `?page=${page}&itemSize=${rowsPerPage}`;
      } else {
        apiRes = await dispatch(createNewProduct(productData));
      }
      if (apiRes.payload) {
        dispatch(getProductsByClinic(reqUrl));
        setModVisible(!modVisible);
        setProductId(null);
        setProductValues(initialValues);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteModal = async (id) => {
    setProductId(id);
    setDelModVisible(true);
  };

  const handleDelete = async () => {
    const apiRes = await dispatch(deleteProducts(productId));
    if (apiRes?.payload) {
      dispatch(getProductsByClinic(defaultUrl));
      setDelModVisible(false);
      setProductId(null);
    }
  };

  const handleEditModal = (id) => {
    const selectedProduct = productsData?.data?.find(
      (product) => product.productId === id
    );
    if (selectedProduct) {
      setProductValues({
        ...productValues,
        category: selectedProduct?.category,
        subCategory: selectedProduct?.subCategory,
        name: selectedProduct?.name,
        tradeName: selectedProduct?.tradeName,
        ingredient: selectedProduct?.ingredient,
        nature: selectedProduct?.nature,
        code: selectedProduct?.code,
        brand: selectedProduct?.brand,
        measurementUnit: selectedProduct?.measurementUnit,
        subMeasurementUnit: selectedProduct?.subMeasurementUnit,
        formula: selectedProduct?.formula,
        batchNumber: selectedProduct?.batchNumber,
        manufacturingDate: selectedProduct?.manufacturingDate,
        expiryDate: selectedProduct?.expiryDate,
        location: selectedProduct?.location,
        sellPrice: parseFloat(selectedProduct?.sellPrice),
        subunitSellingPrice: parseFloat(selectedProduct?.subunitSellingPrice),
        administeringCharges: selectedProduct?.administeringCharges,
        taxApplicability: selectedProduct?.taxApplicability,
        tax: parseFloat(selectedProduct?.tax) ?? 0,
        dosageRecommendations: selectedProduct?.dosageRecommendations,
        composition: selectedProduct?.composition,
        sideEffects: selectedProduct?.sideEffects,
        instructions: selectedProduct?.instructions,
        description: selectedProduct?.description,
      });
      setProductId(id);
      setModVisible(!modVisible);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
    setTableProducts([]);
    // dispatch(getProductsByClinic(`?search=${searchValue}&page=1`));
  };

  const handleChangePage = (e, selectedPage) => {
    setPage(selectedPage);
    setTableProducts([]);
    dispatch(
      getProductsByClinic(
        `?page=${selectedPage}&itemSize=${rowsPerPage}${
          searchValue?.length > 0 ? `&search=${searchValue}` : ""
        }`
      )
    );
  };

  const handleChange = (name, value) => {
    setProductValues({ ...productValues, [name]: value });
    if (value?.length > 0) {
      setProductErrors({ ...productErrors, [name]: false });
      setProductHelps({ ...productHelps, [name]: "" });
    } else {
      setProductErrors({
        ...productErrors,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? false
            : true
          : false,
      });
      setProductHelps({
        ...productHelps,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? ""
            : "This is reuired field"
          : "",
      });
    }
  };

  const handleDelModClose = () => {
    setDelModVisible(false);
    setProductId(null);
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white pv10">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="flex-row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "600px" }}
            >
              <CustomTextField
                search
                placeholder={"Search"}
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={handleSearch}
              />
            </Grid>
          </div>
          <div className="w5Per ml20">
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <div className="">
                <CustomButton
                  text="Create"
                  smallBtn
                  onClick={() => setModVisible(true)}
                />
              </div>
            </Grid>
          </div>
          <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total number of products: {productsData?.totalRecords}
            </Typography>
          </div>
        </Grid>
        <Table
          columns={tableHeaders}
          datas={tableProducts}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          totalRecords={productsData?.totalRecords}
          grey
          product={true}
          onEdit={() => handleEditModal(productId)}
        />
        <CustomModal
          open={modVisible}
          onClose={handleModalClose}
          header={productId ? "Edit" : "Create"}
          modalWidth={100}
          modal
        >
          <div className="ph20 scroll-80vh">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="font-bold fs16 blue-color">
                  Product Details
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Category <span className="red-color">*</span>
                </div>
                <Select
                  list={productCategoryList}
                  value={productValues?.category}
                  handleChange={(e) =>
                    handleChange("category", e?.target?.value)
                  }
                  name="category"
                  select
                  error={productErrors?.category}
                  helperText={productHelps?.category}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Sub-Category <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="subCategory"
                  fullWidth
                  value={productValues?.subCategory}
                  error={productErrors?.subCategory}
                  helperText={productHelps?.subCategory}
                  handleChange={(e) =>
                    handleChange("subCategory", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Name <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="name"
                  fullWidth
                  value={productValues?.name}
                  error={productErrors?.name}
                  helperText={productHelps?.name}
                  handleChange={(e) => handleChange("name", e?.target?.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Trade Name <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="tradeName"
                  fullWidth
                  value={productValues?.tradeName}
                  error={productErrors?.tradeName}
                  helperText={productHelps?.tradeName}
                  handleChange={(e) =>
                    handleChange("tradeName", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Major Active Ingredients
                </div>
                <CustomTextField
                  name="ingredient"
                  fullWidth
                  value={productValues?.ingredient}
                  error={productErrors?.ingredient}
                  helperText={productHelps?.ingredient}
                  handleChange={(e) =>
                    handleChange("ingredient", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Nature</div>
                <Select
                  list={natureList}
                  value={productValues?.nature}
                  handleChange={(e) => handleChange("nature", e?.target?.value)}
                  name="nature"
                  select
                  error={productErrors?.nature}
                  helperText={productHelps?.nature}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Bar Code number
                </div>
                <CustomTextField
                  name="code"
                  fullWidth
                  value={productValues?.code}
                  error={productErrors?.code}
                  helperText={productHelps?.code}
                  handleChange={(e) => handleChange("code", e?.target?.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Brand</div>
                <CustomTextField
                  name="brand"
                  fullWidth
                  value={productValues?.brand}
                  error={productErrors?.brand}
                  helperText={productHelps?.brand}
                  handleChange={(e) => handleChange("brand", e?.target?.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Measurement Unit <span className="red-color">*</span>
                </div>
                <Select
                  list={productMeasurementUnit}
                  value={productValues?.measurementUnit}
                  handleChange={(e) =>
                    handleChange("measurementUnit", e?.target?.value)
                  }
                  name="measurementUnit"
                  select
                  error={productErrors?.measurementUnit}
                  helperText={productHelps?.measurementUnit}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Sub-Measurement Unit <span className="red-color">*</span>
                </div>
                <Select
                  list={productSubMeasurementUnit}
                  value={productValues?.subMeasurementUnit}
                  handleChange={(e) =>
                    handleChange("subMeasurementUnit", e?.target?.value)
                  }
                  name="subMeasurementUnit"
                  select
                  error={productErrors?.subMeasurementUnit}
                  helperText={productHelps?.subMeasurementUnit}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Formula</div>
                <CustomTextField
                  name="formula"
                  fullWidth
                  value={productValues?.formula}
                  error={productErrors?.formula}
                  helperText={productHelps?.formula}
                  handleChange={(e) =>
                    handleChange("formula", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Batch Number
                </div>
                <CustomTextField
                  name="batchNumber"
                  fullWidth
                  value={productValues?.batchNumber}
                  error={productErrors?.batchNumber}
                  helperText={productHelps?.batchNumber}
                  handleChange={(e) =>
                    handleChange("batchNumber", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Mfg Date <span className="red-color">*</span>
                </div>
                <CustomTextField
                  fullWidth
                  value={productValues?.manufacturingDate}
                  error={productErrors?.manufacturingDate}
                  helperText={productHelps?.manufacturingDate}
                  handleChange={(e) =>
                    handleChange("manufacturingDate", e?.target?.value)
                  }
                  mobileDate
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Expiry Date <span className="red-color">*</span>
                </div>
                <CustomTextField
                  fullWidth
                  value={productValues?.expiryDate}
                  error={productErrors?.expiryDate}
                  helperText={productHelps?.expiryDate}
                  handleChange={(e) =>
                    handleChange("expiryDate", e?.target?.value)
                  }
                  mobileDate
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Location <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="location"
                  fullWidth
                  value={productValues?.location}
                  error={productErrors?.location}
                  helperText={productHelps?.location}
                  handleChange={(e) =>
                    handleChange("location", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="font-bold fs16 blue-color">
                  Price & Taxes
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Unit Selling Price (MRP) <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="sellPrice"
                  fullWidth
                  value={productValues?.sellPrice}
                  error={productErrors?.sellPrice}
                  helperText={productHelps?.sellPrice}
                  handleChange={(e) =>
                    handleChange("sellPrice", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Sub-Unit Selling Price <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="subunitSellingPrice"
                  fullWidth
                  value={productValues?.subunitSellingPrice}
                  error={productErrors?.subunitSellingPrice}
                  helperText={productHelps?.subunitSellingPrice}
                  handleChange={(e) =>
                    handleChange("subunitSellingPrice", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Administering Charges
                  {/* <span className="red-color">*</span> */}
                </div>
                <CustomTextField
                  name="administeringCharges"
                  fullWidth
                  value={productValues?.administeringCharges}
                  error={productErrors?.administeringCharges}
                  helperText={productHelps?.administeringCharges}
                  handleChange={(e) =>
                    handleChange("administeringCharges", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Tax applicability <span className="red-color">*</span>
                </div>
                <Select
                  list={yesNoValueList}
                  value={productValues?.taxApplicability}
                  handleChange={(e) =>
                    handleChange("taxApplicability", e?.target?.value)
                  }
                  name="taxApplicability"
                  select
                  error={productErrors?.taxApplicability}
                  helperText={productHelps?.taxApplicability}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Tax % <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="tax"
                  fullWidth
                  value={
                    productValues?.taxApplicability === "No"
                      ? "-"
                      : productValues?.tax
                  }
                  error={
                    productValues?.taxApplicability === "No"
                      ? false
                      : productErrors?.tax
                  }
                  helperText={
                    productValues?.taxApplicability === "No"
                      ? ""
                      : productHelps?.tax
                  }
                  handleChange={(e) => handleChange("tax", e?.target?.value)}
                  disabled={productValues?.taxApplicability === "No"}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="font-bold fs16 blue-color">
                  Product Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Dosage Recommendations
                </div>
                <CustomTextField
                  name="dosageRecommendations"
                  fullWidth
                  value={productValues?.dosageRecommendations}
                  error={productErrors?.dosageRecommendations}
                  helperText={productHelps?.dosageRecommendations}
                  handleChange={(e) =>
                    handleChange("dosageRecommendations", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">Composition</div>
                <CustomTextField
                  name="composition"
                  fullWidth
                  value={productValues?.composition}
                  error={productErrors?.composition}
                  helperText={productHelps?.composition}
                  handleChange={(e) =>
                    handleChange("composition", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Side Effects
                </div>
                <CustomTextField
                  name="sideEffects"
                  fullWidth
                  value={productValues?.sideEffects}
                  error={productErrors?.sideEffects}
                  helperText={productHelps?.sideEffects}
                  handleChange={(e) =>
                    handleChange("sideEffects", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Instructions
                </div>
                <CustomTextField
                  name="instructions"
                  fullWidth
                  value={productValues?.instructions}
                  error={productErrors?.instructions}
                  helperText={productHelps?.instructions}
                  handleChange={(e) =>
                    handleChange("instructions", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Brief Description of the Product
                </div>
                <CustomTextField
                  name="description"
                  fullWidth
                  value={productValues?.description}
                  error={productErrors?.description}
                  helperText={productHelps?.description}
                  handleChange={(e) =>
                    handleChange("description", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
            </Grid>

            <div className="mt30">
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="flex1-end">
                  <div className="flex-row-ali-cen">
                    <div className="mr10">
                      <CustomButton
                        text="Cancel"
                        textBtn
                        onClick={handleModalClose}
                        color={AppColors.red}
                      />
                    </div>
                    <div className="ml10">
                      <CustomButton text="Submit" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={delModVisible}
          onClose={handleDelModClose}
          header="Delete"
          modal
          modalWidth={40}
        >
          <Typography className="txt-regular fs16 ml10">
            Are you sure want to delete this from list?
          </Typography>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="clinic-mod-btn-pos mb10 mt15">
              <div className="mr10">
                <CustomButton text="Cancel" onClick={handleDelModClose} />
              </div>
              <div className="ml10">
                <CustomButton redBtn text={"Confirm"} onClick={handleDelete} />
              </div>
            </div>
          </Grid>
        </CustomModal>
      </div>
    </Container>
  );
};

export default ProductComponent;
