import {
  Box,
  Card,
  CardMedia,
  Container,
  FormHelperText,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import Select from "../../../../components/Select/Select";
import { getProductsByClinic } from "../../../../redux/reducers/clinicSlice";
import {
  createPrescription,
  getPetPrescriptions,
  getPetsCompliantSummary,
  updatePrescriptionById,
} from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";
import { frequencyList } from "../../../../util/arrayList";
import { CardCss } from "../../../../util/object";
import { productCategoryList } from "../../../../util/dropList";

const tableNameExpan = {
  type: "Type",
  name: "Name",
  dosage: "Dosage",
  duration: "Duration",
  frequency: "Frequency",
  meal: "",
};

const tableHeaders = [
  "type",
  "name",
  "dosage",
  "duration",
  "frequency",
  "meal",
];
const initialPres = {
  type: "Deworming",
  name: null,
  dosage: "1",
  duration: "1",
  frequency: "1-1-1",
  meal: "afterMeal",
  price: 0,
  tax: 0,
};

const Prescription = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { appointment } = location.state || {};
  const [prescriptionList, setPrescriptionList] = useState([
    { ...initialPres, i: 0 },
  ]);
  const [remark, setRemark] = useState("");
  const [prevModVisible, setPrevModVisible] = useState(false);
  const [isPreUploaded, setPreUploaded] = useState(false);
  const petDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const apntmnt = petDet?.appointment;
  const [viewPresValue, setViewPresValue] = useState(null);
  const prescriptions = useSelector((state) => state?.pet?.prescriptions);
  const [searchValue, setSearchValue] = useState("");
  const metHisDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.medicalHistory
  );
  const [page, setPage] = useState(1);
  const itemsPerLoad = 3;
  const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [tblVisible, setTblVisible] = useState(false);
  const [notValErr, setNotValErr] = useState(false);
  const products = useSelector((state) => state?.clinic?.products);
  const [selectedPrescription, setSelectedPrescrision] = useState(null);

  useEffect(() => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getProductsByClinic());
  }, []);

  const getListBySelectedType = (typ) => {
    const filteredList = products?.data
      ?.filter((prd) => prd?.category === typ)
      ?.map((pd) => ({
        ...pd,
        value: pd?.name,
      }));
    return filteredList;
  };

  const handleAfterUpdate = () => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getPetsCompliantSummary(appointment?.appoinment?.appoimentId));
    setPage(1);
    setPreUploaded(true);
    setSelectedPrescrision(null);
  };

  const handleAddAnUpdatePrescription = async () => {
    if (
      prescriptionList?.length === 0 ||
      !prescriptionList?.length ||
      prescriptionList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }

    const prescription = prescriptionList?.map((pre) => {
      const freq = pre?.frequency?.split("-");
      const reqObj = {
        type: pre?.type,
        name: pre?.name,
        does: pre?.dosage,
        duration: pre?.duration,
        meal: pre?.meal,
        price: pre?.price ?? 0,
        tax: pre?.tax ?? 0,
        morning: freq?.[0] === "1" ? true : false,
        afternoon: freq?.[1] === "1" ? true : false,
        night: freq?.[2] === "1" ? true : false,
      };
      if (pre?.productId) reqObj.productId = pre?.productId;
      if (pre?.serviceId) reqObj.serviceId = pre?.serviceId;
      if (selectedPrescription && pre?.id) reqObj.detailId = pre?.id;
      return reqObj;
    });

    const data = {
      status: selectedPrescription?.status ?? "Pending",
      remark,
      prescription,
      issue: metHisDet?.[0]?.problemType ?? "",
    };
    if (selectedPrescription) {
      dispatch(
        updatePrescriptionById({
          prescrisionId: selectedPrescription?.prescrisionId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    } else {
      dispatch(
        createPrescription({
          appointmentId: appointment?.appoinment?.appoimentId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    }
  };

  const handleAddNewPres = () =>
    // selectedItem
    {
      setPrescriptionList([
        ...prescriptionList,
        {
          ...initialPres,
          // name: selectedItem?.name,
          // type:
          //   selectedItem?.type === "Supplement" ? "supplements" : "medications",
          i: prescriptionList?.length,
        },
      ]);
    };

  const handleChangePresValue = (name, value, ind, addObj) => {
    const reqList = prescriptionList?.map((pl, i) => {
      if (i === ind) {
        const reqObj = { ...pl, [name]: value };
        if (name === "name" && addObj) {
          reqObj.price = addObj?.price;
          reqObj.tax = addObj?.tax;
        }
        if (name === "type") reqObj.name = null;
        if (addObj?.productId) reqObj.productId = addObj?.productId;
        if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;
        return reqObj;
      }
      return pl;
    });

    setPrescriptionList(reqList);
    if (
      reqList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }
    setNotValErr(false);
  };

  const handlePreModClose = () => {
    setViewPresValue(null);
    setPrevModVisible(false);
  };

  const getPresItems = (type, count) => {
    const filteredList = viewPresValue
      ? viewPresValue?.prescriptionDetails?.filter((pd) => pd?.type === type)
      : prescriptionList?.filter((pl) => pl?.type === type);
    if (count) return filteredList?.length;

    return filteredList?.length > 0 ? (
      filteredList?.map((li, i) => {
        return (
          <div className="gray-back-con">
            <div className="flex-row">
              <div className="w85Per">
                <div className="flex-row">
                  <div className="font-bold fs12 black">{li?.name}</div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.dosage ?? li?.does}{" "}
                      {type === "medications" ? "tablet" : "spoon"}
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.duration} days
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.meal === "afterMeal" ? "After Meal" : "Before Meal"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blue-back-con fs12 font-medium">
                {viewPresValue
                  ? `${li?.morning ? "1" : "0"}-${li?.afternoon ? "1" : "0"}-${
                      li?.night ? "1" : "0"
                    }`
                  : li?.frequency}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="no-rec">No records available</div>
    );
  };

  const handleViewPres = (presc) => {
    setViewPresValue(presc);
    setPrevModVisible(true);
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: `?page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const deletePresByIndex = (ind) => {
    const reqList = prescriptionList
      .filter((pre, i) => {
        return i !== ind;
      })
      ?.map((rl, i) => ({ ...rl, i }));
    setPrescriptionList(reqList);
  };

  const handleEditPrescription = (pres) => {
    setSelectedPrescrision(pres);
    setRemark(pres?.remark);
    const reqList = pres?.prescriptionDetails?.map((pd, i) => {
      const reqName = products?.data?.find(
        (prd) => prd?.category === pd?.type && prd?.name === pd?.name
      );
      return {
        type: pd?.type,
        name: pd?.name,
        dosage: pd?.does,
        duration: pd?.duration,
        frequency: `${pd?.morning ? "1" : "0"}-${pd?.afternoon ? "1" : "0"}-${
          pd?.night ? "1" : "0"
        }`,
        meal: pd?.meal,
        price: reqName?.sellPrice ?? 0,
        tax: reqName?.tax ?? 0,
        i,
        id: pd?.id,
      };
    });
    setPrescriptionList(reqList);
    setTblVisible(true);
    setPreUploaded(false);
  };

  const getMeasurementUnitByType = (typ, nme) => {
    return (
      products?.data?.find((prd) => prd?.category === typ && prd?.name === nme)
        ?.measurementUnit ?? ""
    );
  };

  return (
    <>
      <div className="scroll-80vh w100Per">
        <Grid container className="back-white">
          {!tblVisible ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {apntmnt?.prescriptionStatus !== "Y" && (
                <div className="mt20 flex-center">
                  <div className="no-rec">
                    No records yet. Create a new prescription now
                  </div>
                </div>
              )}
              <div
                className={`flex-center mb20 ${
                  apntmnt?.prescriptionStatus === "Y" && "mt30"
                }`}
              >
                <div className="w15Per">
                  <CustomButton
                    text="Create"
                    onClick={() => setTblVisible(true)}
                    smallBtn
                  />
                </div>
              </div>
            </Grid>
          ) : tblVisible || apntmnt?.prescriptionStatus !== "Y" ? (
            <Container maxWidth="xl">
              <Box className="pv20">
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    sx={{ minWidth: 200 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        {tableHeaders?.map((hl, i) => (
                          <TableCell
                            key={i}
                            style={{
                              backgroundColor: AppColors.tableGrey,
                              color: AppColors.white,
                              textAlign: "left",
                            }}
                            sortDirection={false}
                            className={`table-header-text ${
                              hl === "name" ? "w30Per" : "w15Per"
                            }`}
                          >
                             {tableNameExpan?.[hl]}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prescriptionList?.map((pr, ind) => (
                        <TableRow tabIndex={-1} key={ind + pr?.name + pr?.i}>
                          {tableHeaders?.map((th, index) => (
                            <TableCell key={index + th + "tr"} component="th">
                              {th === "type" ? (
                                <Select
                                  list={productCategoryList}
                                  value={pr?.type}
                                  select
                                  handleChange={(e) => {
                                    handleChangePresValue(
                                      "type",
                                      e?.target?.value,
                                      ind
                                    );
                                  }}
                                  disabled={isPreUploaded}
                                />
                              ) : th === "name" ? (
                                <Select
                                  list={getListBySelectedType(pr?.type)}
                                  value={pr?.name}
                                  addNewSelect
                                  handleChange={(e) => {
                                    handleChangePresValue(
                                      "name",
                                      e?.value ?? e,
                                      ind,
                                      {
                                        price: e?.sellPrice ?? 0,
                                        tax: e?.tax ?? 0,
                                        productId: e?.productId ?? null,
                                        serviceId: e?.serviceId ?? null,
                                      }
                                    );
                                  }}
                                  disabled={isPreUploaded}
                                />
                              ) : th === "dosage" ? (
                                <CustomTextField
                                  fullWidth
                                  handleChange={(e) =>
                                    handleChangePresValue(
                                      "dosage",
                                      e?.target?.value,
                                      ind
                                    )
                                  }
                                  value={pr?.dosage}
                                  endIcon
                                  inputIcon={getMeasurementUnitByType(
                                    pr?.type,
                                    pr?.name
                                  )}
                                  disabled={isPreUploaded}
                                />
                              ) : th === "duration" ? (
                                <CustomTextField
                                  fullWidth
                                  handleChange={(e) =>
                                    handleChangePresValue(
                                      "duration",
                                      e?.target?.value,
                                      ind
                                    )
                                  }
                                  value={pr?.duration}
                                  endIcon
                                  inputIcon="days"
                                  disabled={isPreUploaded}
                                />
                              ) : th === "frequency" ? (
                                <Select
                                  list={frequencyList}
                                  value={pr?.frequency}
                                  select
                                  handleChange={(e) =>
                                    handleChangePresValue(
                                      "frequency",
                                      e?.target?.value,
                                      ind
                                    )
                                  }
                                  disabled={isPreUploaded}
                                />
                              ) : (
                                <div className="flex-row flex-center">
                                  <Select
                                    list={[
                                      {
                                        name: "Before Meal",
                                        value: "beforeMeal",
                                      },
                                      {
                                        name: "After Meal",
                                        value: "afterMeal",
                                      },
                                    ]}
                                    value={pr?.meal}
                                    select
                                    handleChange={(e) => {
                                      handleChangePresValue(
                                        "meal",
                                        e?.target?.value,
                                        ind
                                      );
                                    }}
                                    disabled={isPreUploaded}
                                  />
                                  {!isPreUploaded ? (
                                    <img
                                      src={require("../../../../assets/images/png/delete.png")}
                                      alt=""
                                      className="imghw30 ml10 cursor"
                                      onClick={() => deletePresByIndex(ind)}
                                    />
                                  ) : null}
                                </div>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {notValErr ? (
                <div className="flex-center">
                  <FormHelperText error>
                    {prescriptionList?.length === 0 || !prescriptionList?.length
                      ? "Please add at least 1 record"
                      : prescriptionList?.some(
                          (pl) =>
                            pl?.name === "" ||
                            pl?.name === null ||
                            pl?.name === undefined
                        )
                      ? "Please add Record"
                      : ""}
                  </FormHelperText>
                </div>
              ) : null}

              {!isPreUploaded && !selectedPrescription ? (
                <div className="flex-end">
                  <div className="w15Per">
                    <CustomButton
                      text="Add New"
                      smallBtn
                      onClick={handleAddNewPres}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mv20">
                <CustomTextField
                  fullWidth
                  multiline
                  placeholder="Advice"
                  rows={3}
                  handleChange={(e) => setRemark(e?.target?.value)}
                  value={remark}
                  disabled={isPreUploaded}
                />
              </div>
              <div className="flex-end">
                <div className="w15Per">
                  <CustomButton
                    text="Preview"
                    smallBtn
                    onClick={() => setPrevModVisible(true)}
                  />
                </div>
                {!isPreUploaded ? (
                  <div className="w15Per ml15">
                    <CustomButton
                      text={selectedPrescription ? "Update" : "Submit"}
                      smallBtn
                      onClick={handleAddAnUpdatePrescription}
                    />
                  </div>
                ) : null}
              </div>
            </Container>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ph20">
            <div className="box-hor-split mv20" />
            <div className="font-bold fs14 mv20 blue-color">
              Prescription History
            </div>
            <div className="flex-center">
              <div className="w75Per">
                <CustomTextField
                  search
                  placeholder="Search"
                  fullWidth
                  backgroundColor={AppColors.lightPink}
                  value={searchValue}
                  handleChange={(e) => setSearchValue(e?.target?.value)}
                />
              </div>
            </div>
            <div className="pb100 w100Per">
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                prescriptions?.data
                  ?.filter(
                    (pr) =>
                      pr?.doctorName
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase()) ||
                      pr?.issue
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase())
                  )
                  ?.map((pres, ind) => (
                    <div className="flex-row-ali-cen mv10" key={ind + "pres"}>
                      <div className="w20Per">
                        <div className="back-img">
                          <div className="flex-center h100">
                            <div className="flex-column flex-center">
                              <Typography className="black2 fs10 font-bold">
                                {moment(new Date(pres?.createdDate)).format(
                                  "DD MMM"
                                )}
                              </Typography>
                              <Typography className="black2 fs10 font-bold mt5">
                                {moment(new Date(pres?.createdDate)).format(
                                  "YYYY"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w60Per">
                        <Card sx={CardCss} className="inner-cards h110">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt10"
                            >
                              <div className="card-top-color card-top-blue-color" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="ph10">
                                <div className="flex-row">
                                  <div className="w90Per">
                                    <div className="flex-start">
                                      <div className="flex-column w10Per">
                                        <div className="h50">
                                          {apntmnt?.doctorImage ? (
                                            <CardMedia
                                              image={apntmnt?.doctorImage}
                                              className="img-h40"
                                            />
                                          ) : (
                                            <div className="flex-center img-hw40 back-gray2">
                                              <Typography className="font-bold fs30 white-color capitalize">
                                                {apntmnt?.doctorName?.[0]}
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                        <div className="card-light-blue-back card-time flex-center">
                                          <Typography className="txt-regular card-blue2 fs12">
                                            {pres?.appoimentTime ??
                                              apntmnt?.appoimentTime}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="flex-column ml15 jus-con-spa-bet">
                                        <div className="h50">
                                          <Typography className="font-bold fs14 black capitalize">
                                            Dr. {apntmnt?.doctorName}
                                          </Typography>
                                          <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                            {pres?.issue}
                                          </Typography>
                                        </div>
                                        <div className="card-con-blue-back card-time flex-center w100Px">
                                          <Typography className="txt-regular white-color fs12">
                                            {apntmnt?.appoinmentType}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-center h90">
                                      <img
                                        src={require("../../../../assets/images/png/edit2.png")}
                                        alt=""
                                        className="img-wh25 cursor"
                                        onClick={() =>
                                          handleEditPrescription(pres)
                                        }
                                      />

                                      <img
                                        src={require("../../../../assets/images/png/view.png")}
                                        alt=""
                                        className="img-hw40 ml10 cursor"
                                        onClick={() => handleViewPres(pres)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-rec">No records available</div>
              )}
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                Math.ceil(prescriptions?.totalRecords / itemsPerLoad) > 1 ? (
                  <div className="flex-end">
                    <Pagination
                      count={Math.ceil(
                        prescriptions?.totalRecords / itemsPerLoad
                      )}
                      variant="outlined"
                      color="primary"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                ) : null
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      <CustomModal
        open={prevModVisible}
        onClose={handlePreModClose}
        header=""
        modal
        modalWidth={50}
      >
        <div className="scroll-80vh">
          <div className="p5">
            <div className="blue-box-examD">
              <div className="flex-row">
                <div className="w33Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Pet Name:</div>
                      <div className="font-bold fs14 black capitalize ml5">
                        {appointment?.appoinment?.petName}
                      </div>
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Breed:</div>
                      <div className="txt-regular fs14 black capitalize ml5">
                        {appointment?.appoinment?.breed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w33Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Vet Name:</div>
                      <div className="font-bold fs14 black ml5 capitalize">
                        Dr. {appointment?.appoinment?.doctorName}
                      </div>
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Date:</div>
                      <div className="txt-regular fs14 black ml5">
                        {viewPresValue
                          ? moment(viewPresValue?.createdDate).format(
                              "DD MMM YYYY"
                            )
                          : moment().format("DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w33Per">
                  <div className="flex-end">
                    <img
                      alt=""
                      src={
                        profile?.image ??
                        require("../../../../assets/images/png/applologo.png")
                      }
                      className="img-h50"
                    />
                  </div>
                </div>
              </div>
              <div className="box-hor-split mv10" />
              {getPresItems("medications", true) > 0 ? (
                <>
                  <div className="font-bold fs14 blue-color mv10">
                    Medications
                  </div>
                  {getPresItems("medications")}
                </>
              ) : null}
              {getPresItems("supplements", true) > 0 ? (
                <>
                  <div className="font-bold fs14 blue-color mv10">
                    Supplements
                  </div>
                  {getPresItems("supplements")}
                </>
              ) : null}
              <div className="font-bold fs14 blue-color mv10">Advice</div>
              <CustomTextField
                fullWidth
                multiline
                placeholder="Advice"
                rows={3}
                value={viewPresValue ? viewPresValue?.remark : remark}
              />
              <div className="flex-row mv20">
                <img
                  src={require("../../../../assets/images/png/qrcodedown.png")}
                  alt=""
                  className="img-h50"
                />
                <div className="blu-back wh50 flex-center">
                  <img
                    src={require("../../../../assets/images/png/VetInstantLogo.png")}
                    alt=""
                    className="img-h40"
                  />
                </div>
                <div className="flex-center">
                  <div className="font-bold fs14 ml20">
                    Scan to download our app
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Prescription;
