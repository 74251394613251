// import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import "./App.css";
// import Snackbar from "./components/CustomSnackbar/CustomSnackbar";
// import Loader from "./components/Loader";
// import { navWidths } from "./redux/reducers/loaderSlice";
// import Router from "./routes/Router";
// import ThemeProvider from "./theme";

// const App = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(navWidths(false));
//   }, []);

//   return (
//     <>
//       <Loader />
//       <ThemeProvider>
//         <Router />
//         <Snackbar />
//       </ThemeProvider>
//     </>
//   );
// };

// export default App;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import { navWidths } from "./redux/reducers/loaderSlice";
import Router from "./routes/Router";
import SockJS from 'sockjs-client/dist/sockjs';
import Stomp from 'stompjs';
import { socketServer } from "./util/server";
import { showSnackBar } from "./redux/reducers/snackSlice";


const App = () => {

  const dispatch = useDispatch();
  const stompClientRef = useRef(null);
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
console.log("profile", profile);

  useEffect(() => {
    dispatch(navWidths(false));
    if (!profile?.id) {
      return;
    }
    const stompClient = Stomp.over(new SockJS(socketServer));
    stompClientRef.current = stompClient;

    stompClient.connect(
      {},
      () => {
        console.log('WebSocket connection opened');
        stompClient.subscribe(
          `/user/3/topic/notification`,
          message => {
            // console.log("Server,Server,Server,Server", message);
            const parsedData = JSON.parse(message?.body);
            dispatch(showSnackBar({
              title: parsedData?.title,
              message: parsedData?.message,
              type: "notification",
            }))
          },
        );
      },
      error => {
        console.error('WebSocket connection error:', error);
      },
    );

    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, [profile?.id]);


  return (
    <>
      {/* <Loader /> */}
      {/* <ThemeProvider> */}
        <Router />
        {/* <Snackbar /> */}
      {/* </ThemeProvider> */}
    </>
  );
};

export default App;
